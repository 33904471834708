<template>
  <div>
    <ejs-dialog
      ref="createConfigKakaoSendKeyPopup"
      :header="`환경설정-카카오 알림톡 발신 key`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onConfigKakaoSendKeyPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      v-on:click.native="onConfigKakaoSendKeyGet"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        카카오 알림톡 발신 key 목록
                      </div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="configKakaoSendKeyPopup.shortcuts.addSendKey"
                              :shortcut="{key: 'F3'}"
                              v-on:click.native="onConfigKakaoSendKeyAdd"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              @click.native="onConfigKakaoSendKeyDelete"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="kakaoSendKeyGrid"
                        :allowResizing="true"
                        :dataSource="kakaoSendKeyList"
                        :isInPopup="true"
                        @queryCellInfo="kakaoSendKeyGridCustomiseCell"
                        :isNOColumnDisplay="true"
                        @headerCellInfo="kakaoSendKeyGridHeaderCellInfo"
                        @onGridDialogDoubleClickedOrEnterKeyed="
                          onConfigKakaoSendKeySave
                        "
                        :validationRules="gridValidationRules"
                        :columns="kakaoSendKeyColumn"
                        :provides="grid"
                        :editSettings="editSettings"
                        :selectionSettings="selectionOptions"
                        :cellSave="cellSave"
                      ></ejs-grid-wrapper>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onConfigKakaoSendKeySave"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onConfigKakaoSendKeyPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Edit, ForeignKey } from "@syncfusion/ej2-vue-grids";
import { getKakaoSendKeyInfo, putKakaoSendKeyInfo } from "@/api/systemConfig";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigKakaoSendKeyPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  async mounted() {
    await this.fetchKakaoSendKeyList();
  },
  data() {
    return {
      grid: [Edit, ForeignKey],
      kakaoSendKeyColumn: [
        {
          field: "_rid",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "tmplatCode",
          headerText: "구분코드",
          allowEditing: true,
          width: "90",
          textAlign: "Center",
        },
        {
          field: "tmplatName",
          headerText: "구분명",
          allowEditing: true,
          width: "90",
          textAlign: "Center",
        },
        {
          field: "dsptchKey",
          headerText: "발신KEY",
          allowEditing: true,
          width: "90",
          textAlign: "Center",
        },
        {
          field: "sortNo",
          headerText: "정렬",
          isNumericType: true,
          inputNumberProperty: { maxLength: 4 },
          type: "string",
          width: "90",
          textAlign: "Right",
        },
      ],
      kakaoSendKeyList: [],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Bottom",
      },
      selectionOptions: { type: "Multiple" },
      editparams: {
        params: {
          fields: { text: "comName", value: "comCode" },
          actionComplete: () => true,
        },
      },
      numericParams: {
        params: {
          validateDecimalOnType: true,
          decimals: 0,
          min: 0,
          format: "N",
        },
      },
      gridValidationRules: {
        tmplatCode: {
          required: true,
          maxLength: 50,
        },
        tmplatName: {
          maxLength: 50,
          required: true,
        },
        dsptchKey: {
          maxLength: 100,
        },
      },
    };
  },
  methods: {
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    async fetchKakaoSendKeyList() {
      const {
        value: { tsConfKakaoTemplateList },
      } = await getKakaoSendKeyInfo();
      this.kakaoSendKeyList = tsConfKakaoTemplateList.map((record, index) => {
        return {
          ...record,
          _rid: index + 1,
          pk: index + 10,
        };
      });
    },
    onShowConfigKakaoSendKeyPopup() {
      this.$refs.createConfigKakaoSendKeyPopup.show();
    },
    onConfigKakaoSendKeyPopupClosed() {
      this.$emit("popupClosed");
      this.$refs.createConfigKakaoSendKeyPopup.hide();
    },
    async onConfigKakaoSendKeyGet() {
      if (this.$refs.kakaoSendKeyGrid.isGridModified()) {
        if (
          !(await this.confirm("변경된 데이터가 있습니다. 조회하시겠습니까?"))
        ) {
          return;
        }
      }
      await this.fetchKakaoSendKeyList();
    },
    onConfigKakaoSendKeyAdd() {
      this.$refs.kakaoSendKeyGrid.addRecord({
        tmplatCode: null,
        tmplatName: null,
        dsptchKey: null,
        sortNo: 0,
      });
    },
    onConfigKakaoSendKeyDelete() {
      this.$refs.kakaoSendKeyGrid.deleteRecord();
    },
    async onConfigKakaoSendKeySave() {
      if (!this.$refs.kakaoSendKeyGrid.validate()) {
        return;
      }
      const changedObj = this.$refs.kakaoSendKeyGrid.getBatchChanges();
      const tmpBody = {
        addedRecords: [],
        changedRecords: [],
        deletedRecords: [],
      };
      if (changedObj.addedRecords.length > 0) {
        tmpBody.addedRecords = changedObj.addedRecords;
      }
      if (changedObj.changedRecords.length > 0) {
        tmpBody.changedRecords = changedObj.changedRecords;
      }
      if (changedObj.deletedRecords.length > 0) {
        tmpBody.deletedRecords = changedObj.deletedRecords;
      }
      if (
        changedObj.changedRecords.length > 0 ||
        changedObj.addedRecords.length > 0 ||
        changedObj.deletedRecords.length > 0
      ) {
        await putKakaoSendKeyInfo(tmpBody);
        this.infoToast(this.$t("main.popupMessage.saved"));
        await this.fetchKakaoSendKeyList();
      }
    },
    kakaoSendKeyGridCustomiseCell(args) {
      args.cell.classList.add(this.$t("className.grid.modifyArea"));
    },
    kakaoSendKeyGridHeaderCellInfo(args) {
      if (
        args.cell.column.field === "tmplatCode" ||
        args.cell.column.field === "tmplatName"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    cellSave(e) {
      if (
        (isNaN(e.value) || e.value === null) &&
        e.columnName.includes("sortNo")
      ) {
        e.value = 0;
      }
    },
  },
};
</script>
