<template>
  <div>
    <ejs-dialog
      ref="createConfigPaymentLinePopup"
      width="1180"
      :header="`환경설정-결재선`"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onConfigPaymentLinePopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">결재선 정보</div>
                    </div>
                    <div class="header-right">
                      <ul class="lookup-condition">
                        <li class="field">
                          <ul class="content">
                            <li class="item">
                              <ul class="check">
                                <li>
                                  <label>
                                    <input type="checkbox" v-model="useFlag" />
                                    <i></i>
                                    <div class="label">사용</div>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <div class="lookup-lookup">
                        <erp-button
                            button-div="GET"
                            :is-shortcut-button="true"
                            v-on:click.native="onConfigPaymentLineGet"
                        >
                          조회
                        </erp-button>
                      </div>
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="configPaymentListPopup.shortcuts.addButton"
                              :shortcut="{key: 'F3'}"
                              v-on:click.native="onConfigPaymentLineAdd"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              @click.native="onConfigPaymentLineDelete"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div
                    class="section-body"
                    v-if="isRefresh"
                    style="border: none"
                  >
                    <div class="body-data header">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li class="field" style="width: 50px">
                              <!-- 필수 : required -->
                              <div class="title">NO</div>
                            </li>
                            <li class="field" style="width: 200px">
                              <!-- 필수 : required -->
                              <div class="title">결재선명</div>
                            </li>
                            <li class="field" style="width: 50px">
                              <!-- 필수 : required -->
                              <div class="title">사용</div>
                            </li>
                            <li
                              class="field"
                              style="width: calc((100% + 1px) - 314px)"
                            >
                              <!-- 필수 : required -->
                              <div class="title">결재라인</div>
                            </li>
                            <li class="field scroll">
                              <!-- 필수 : required -->
                              <div class="title">스크롤</div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <div class="body-data body">
                      <div
                        class="data-outer"
                        style="height: 600px; overflow-y: scroll"
                      >
                        <div class="data-inner">
                          <ul
                            class="data-content"
                            v-for="(item, outIndex) in paymentLineList"
                            :key="item.sanctnLineId"
                          >
                            <li class="field" style="width: 50px">
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group"
                                    style="
                                      text-align: center;
                                      line-height: 0;
                                      padding: 36px 0 35px 0;
                                    "
                                  >
                                    <!-- 포커스 : focus -->
                                    <ul
                                      class="check"
                                      style="display: inline-block"
                                    >
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="item.checked"
                                          />
                                          <i></i>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field paymentName" style="width: 200px">
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <div class="form">
                                      <input-text
                                        v-model="item.sanctnLineName"
                                        :style="{
                                          'text-decoration': item.useFlag
                                            ? ''
                                            : 'line-through',
                                          'text-decoration-color': item.useFlag
                                            ? ''
                                            : 'red',
                                        }"
                                      />
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li class="field" style="width: 50px">
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group"
                                    style="
                                      text-align: center;
                                      line-height: 0;
                                      padding: 36px 0 35px 0;
                                    "
                                  >
                                    <!-- 포커스 : focus -->
                                    <ul
                                      class="check"
                                      style="display: inline-block"
                                    >
                                      <li>
                                        <label>
                                          <input
                                            type="checkbox"
                                            v-model="item.useFlag"
                                          />
                                          <i></i>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              class="field paymentLine"
                              style="width: calc((100% + 1px) - 314px + 17px)"
                            >
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group"
                                    v-for="(
                                      inner, innerIndex
                                    ) in item.tsConfSanctnDetail"
                                    :key="`${item.headerName}_${innerIndex}`"
                                    style="width: 90px"
                                  >
                                    <div class="form">
                                      <div style="margin: -3px -6px -2px -6px">
                                        <div class="paymentLineRow">
                                          <div>
                                            <input-number
                                              v-model="inner.sortNo"
                                              :min="0"
                                              :prop-max-length="3"
                                              @change="
                                                onNumeriChange(
                                                  $event,
                                                  outIndex,
                                                  innerIndex
                                                )
                                              "
                                            />
                                          </div>
                                          <div class="paymentLineDelete">
                                            <erp-button
                                                button-div="DELETE"
                                                v-on:click.native="
                                                onDeleteLine(
                                                  outIndex,
                                                  innerIndex
                                                )
                                              "
                                            >
                                              삭제
                                            </erp-button>
                                          </div>
                                        </div>
                                        <div class="paymentLineRow">
                                          <input-text
                                            v-model="inner.headerName"
                                          />
                                        </div>
                                        <div style="height: 41px"></div>
                                      </div>
                                    </div>
                                  </li>
                                  <li
                                    class="item form-group"
                                    style="
                                      width: 90px;
                                      border-right: 1px solid #e0e0e0;
                                    "
                                  >
                                    <div class="paymentLineAdd">
                                      <erp-button
                                          button-div="SAVE"
                                          @click.native="onAddLine(outIndex)"
                                      >
                                        +
                                      </erp-button>
                                    </div>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onConfigPaymentLineSave"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onConfigPaymentLinePopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
.paymentName .e-input {height: 84px;}
.paymentLine .inputNumber {width: 100% !important; margin: 0 !important;}
.paymentLine .inputNumber >>> input {text-align: center !important;}
.paymentLine .paymentLineRow {position: relative; border-bottom: 1px solid #e0e0e0;}
.paymentLine .e-input {box-sizing: border-box; width: 100% !important; height: 23px; margin: 0 !important; text-align: center;}
.paymentLine .paymentLineDelete {position: absolute; top: 0; right: 0;}
.paymentLine .paymentLineDelete .e-btn {display: block; overflow: hidden; box-shadow: none; width: 23px; height: 23px; padding: 0; border: none; background: transparent url('../../../assets/images/common/paymentLine-delete.png') no-repeat center center; text-indent: -1000px;}
.paymentLine .paymentLineDelete .e-btn >>> .e-ripple-element {background-color: transparent;}
.paymentLine .paymentLineAdd {margin: -3px -6px -2px -6px;}
.paymentLine .paymentLineAdd .e-btn {display: block; overflow: hidden; box-shadow: none; width: 89px; height: 89px; padding: 0; border: none; background: #fafafa url('../../../assets/images/common/paymentLine-add.png') no-repeat center center; text-indent: -1000px;}
.paymentLine .paymentLineAdd .e-btn >>> .e-ripple-element {background-color: transparent;}
</style>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { getPaymentLineInfo, putPaymentLineInfo } from "@/api/systemConfig";
import InputNumber from "@/components/common/InputNumber";
import InputText from "@/components/common/text/InputText";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigPaymentLinePopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    InputText,
    InputNumber,
    ErpButton,
  },
  async mounted() {
    await this.fetchPaymentLineList();
  },
  data() {
    return {
      useFlag: false,
      isRefresh: true,
      changedRecords: [],
      originPaymentLineList: [],
      paymentLineList: [],
    };
  },
  methods: {
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    async fetchPaymentLineList() {
      const {
        value: { sanctnList },
      } = await getPaymentLineInfo(this.useFlag);
      this.changedRecords = [];
      this.paymentLineList = sanctnList;
      this.originPaymentLineList = JSON.parse(
        JSON.stringify(this.paymentLineList)
      );
    },
    onShowConfigPaymentLinePopup() {
      this.$refs.createConfigPaymentLinePopup.show();
    },
    onConfigPaymentLinePopupClosed() {
      this.$emit("popupClosed");
      this.$refs.createConfigPaymentLinePopup.hide();
    },
    onConfigPaymentLineAdd() {
      let max = 1;
      if (this.paymentLineList.length > 0) {
        const maxObj = this.paymentLineList.reduce((prev, current) => {
          return prev.sanctnLineId > current.sanctnLineId ? prev : current;
        });
        max = maxObj.sanctnLineId + 1;
      }
      const newRecord = {
        sanctnLineId: max,
        checked: false,
        sanctnLineName: "",
        useFlag: true,
        tsConfSanctnDetail: [],
      };
      this.paymentLineList.push(newRecord);
    },
    async onConfigPaymentLineGet() {
      await this.fetchPaymentLineList();
    },
    async onConfigPaymentLineSave() {
      const addedRecords = this.paymentLineList.filter((data) => {
        const hasRecord = this.originPaymentLineList.find((orgint) => {
          return orgint.sanctnLineId === data.sanctnLineId;
        });
        if (hasRecord === undefined) {
          return true;
        } else {
          return false;
        }
      });

      const deletedRecords = this.originPaymentLineList
        .map((data) => {
          const hasRecord = this.paymentLineList.find(
            (orgint) => orgint.sanctnLineId === data.sanctnLineId
          );

          if (hasRecord === undefined) {
            data.listFlag = true;
            return data;
          } else {
            const tmp = data.tsConfSanctnDetail.filter((innerData) => {
              const hasInnerData = hasRecord.tsConfSanctnDetail.find(
                (innerHasData) => innerHasData.dtlId === innerData.dtlId
              );
              if (hasInnerData) {
                return false;
              } else {
                return true;
              }
            });
            if (tmp.length > 0) {
              data.listFlag = false;
              data.tsConfSanctnDetail = tmp;
              return data;
            }
            return null;
          }
        })
        .filter((data) => data);

      const changedRecords = JSON.parse(JSON.stringify(this.paymentLineList))
        .map((data) => {
          const originRecord = this.originPaymentLineList.find(
            (originData) => originData.sanctnLineId === data.sanctnLineId
          );
          if (originRecord) {
            if (
              data.sanctnLineName !== originRecord.sanctnLineName ||
              data.useFlag !== originRecord.useFlag
            ) {
              data.listFlag = true;
            } else {
              data.listFlag = false;
            }
            data.tsConfSanctnDetail = data.tsConfSanctnDetail
              .map((detailData) => {
                const originDetailData = originRecord.tsConfSanctnDetail.find(
                  (x) => x.dtlId === detailData.dtlId
                );
                if (originDetailData) {
                  if (
                    detailData.headerName !== originDetailData.headerName ||
                    detailData.sortNo !== originDetailData.sortNo
                  ) {
                    return detailData;
                  }
                } else {
                  return detailData;
                }
              })
              .filter((x) => x);
            if (data.listFlag === true || data.tsConfSanctnDetail.length > 0) {
              return data;
            }
          }
        })
        .filter((d) => d);
      const body = { addedRecords, deletedRecords, changedRecords };
      await putPaymentLineInfo(body);
      this.infoToast(this.$t("main.popupMessage.saved"));
      await this.fetchPaymentLineList();
    },
    async onConfigPaymentLineDelete() {
      if (await this.confirm("삭제하시겠습니까?")) {
        this.paymentLineList = this.paymentLineList.filter(
          (data) => !data.checked
        );
      }
    },
    onAddLine(outIndex) {
      this.paymentLineList[outIndex].tsConfSanctnDetail.push({
        headerName: "",
        sortNo: 0,
      });
    },
    async onDeleteLine(outIndex, innerIndex) {
      if (await this.confirm("삭제하시겠습니까?")) {
        const tmpLineArr = this.paymentLineList[
          outIndex
        ].tsConfSanctnDetail.filter((data, index) => {
          return index !== innerIndex;
        });
        this.paymentLineList[outIndex].tsConfSanctnDetail = tmpLineArr;
      }
    },
    onNumeriChange(e, outIndex, innerIndex) {
      if (e.value === null) {
        this.paymentLineList[outIndex].tsConfSanctnDetail[
          innerIndex
        ].sortNo = 0;
        this.isRefresh = false;
        this.$nextTick(() => {
          this.isRefresh = true;
        });
      }
    },
  },
};
</script>
