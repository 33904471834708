<template>
  <div>
    <ejs-dialog
      ref="createConfigEndCheckListPopup"
      :header="`환경설정-일마감 체크항목`"
      width="1180"
      height="800"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onConfigEndCheckListPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">일마감 체크항목 목록</div>
                    </div>
                    <div class="header-right">
                      <ul class="lookup-condition">
                        <li class="field">
                          <ul class="content">
                            <li class="item">
                              <ul class="check">
                                <li>
                                  <label>
                                    <input type="checkbox" v-model="useFlag" />
                                    <i></i>
                                    <div class="label">사용</div>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul class="header-button">
                        <div class="lookup-lookup">
                          <erp-button
                              button-div="GET"
                              :is-shortcut-button="true"
                              v-on:click.native="onConfigEndCheckListGet"
                          >
                            조회
                          </erp-button>
                        </div>
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="configEndCheckListPopup.shortcuts.addButton"
                              :shortcut="{key: 'F3'}"
                              v-on:click.native="onConfigEndCheckListAdd"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              @click.native="onConfigEndCheckListDelete"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="endCheckListGrid"
                        :allowResizing="true"
                        :dataSource="endCheckListList"
                        :validationRules="gridValidationRules"
                        :columns="endCheckListColumn"
                        :provides="grid"
                        :editSettings="editSettings"
                        :selectionSettings="selectionOptions"
                        @queryCellInfo="endCheckListGridCustomiseCell"
                        @headerCellInfo="endCheckListGridHeaderCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onConfigEndCheckListSave"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onConfigEndCheckListPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Edit, ForeignKey } from "@syncfusion/ej2-vue-grids";
import { getClosingCheckInfo, putClosingCheckInfo } from "@/api/systemConfig";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigEndCheckListPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  async mounted() {
    await this.fetchEndCheckList();
  },
  data() {
    return {
      useFlag: true,
      endCheckListList: [],
      endCheckListColumn: [
        {
          field: "_rid",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "checkItem",
          headerText: "체크항목",
          allowEditing: true,
          width: "120",
          textAlign: "Left",
        },
        {
          field: "description",
          headerText: "설명",
          allowEditing: true,
          width: "180",
          textAlign: "Left",
        },
        {
          field: "checkFunc",
          headerText: "함수",
          allowEditing: true,
          width: "180",
          textAlign: "Left",
        },

        {
          field: "tableId",
          headerText: "테이블",
          allowEditing: true,
          width: "180",
          textAlign: "Left",
        },
        {
          field: "sortNo",
          headerText: "정렬",
          isNumericType: true,
          inputNumberProperty: { maxLength: 3 },
          type: "string",
          width: "60",
          textAlign: "Center",
        },
        {
          field: "useFlag",
          headerText: "사용",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          width: "60",
          textAlign: "Center",
        },
      ],
      grid: [Edit, ForeignKey],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Bottom",
      },
      selectionOptions: { type: "Multiple" },
      editparams: {
        params: {
          fields: { text: "comName", value: "comCode" },
          actionComplete: () => true,
        },
      },
      numericParams: {
        params: {
          validateDecimalOnType: true,
          decimals: 0,
          min: 0,
          format: "N",
        },
      },
      gridValidationRules: {
        checkItem: {
          required: true,
          maxLength: 50,
        },
        checkFunc: {
          maxLength: 50,
        },
        description: {
          maxLength: 100,
        },
        tableId: {
          maxLength: 50,
        },
      },
    };
  },
  methods: {
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    async fetchEndCheckList() {
      const {
        value: { checkList },
      } = await getClosingCheckInfo(this.useFlag);
      this.endCheckListList = checkList.map((record, index) => {
        return {
          ...record,
          _rid: index + 1,
          pk: index + 10,
        };
      });
    },
    onShowConfigEndCheckListPopup() {
      this.$refs.createConfigEndCheckListPopup.show();
    },
    onConfigEndCheckListPopupClosed() {
      this.$emit("popupClosed");
      this.$refs.createConfigEndCheckListPopup.hide();
    },
    async onConfigEndCheckListGet() {
      if (this.$refs.endCheckListGrid.isGridModified()) {
        if (
          !(await this.confirm("변경된 데이터가 있습니다. 조회하시겠습니까?"))
        ) {
          return;
        }
      }
      await this.fetchEndCheckList();
    },
    onConfigEndCheckListAdd() {
      this.$refs.endCheckListGrid.addRecord({
        checkItem: null,
        description: null,
        checkFunc: null,
        tableId: null,
        sortNo: 0,
        useFlag: true,
      });
    },
    onConfigEndCheckListDelete() {
      this.$refs.endCheckListGrid.deleteRecord();
    },
    async onConfigEndCheckListSave() {
      if (!this.$refs.endCheckListGrid.validate()) {
        return;
      }
      const changedObj = this.$refs.endCheckListGrid.getBatchChanges();
      const tmpBody = {
        addedRecords: [],
        changedRecords: [],
        deletedRecords: [],
      };
      if (changedObj.addedRecords.length > 0) {
        tmpBody.addedRecords = changedObj.addedRecords;
      }
      if (changedObj.changedRecords.length > 0) {
        tmpBody.changedRecords = changedObj.changedRecords;
      }
      if (changedObj.deletedRecords.length > 0) {
        tmpBody.deletedRecords = changedObj.deletedRecords;
      }
      if (
        changedObj.changedRecords.length > 0 ||
        changedObj.addedRecords.length > 0 ||
        changedObj.deletedRecords.length > 0
      ) {
        await putClosingCheckInfo(tmpBody);
        this.infoToast(this.$t("main.popupMessage.saved"));
        await this.fetchEndCheckList();
      }
    },
    endCheckListGridCustomiseCell(args) {
      if (args.column.allowEditing === true) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (args.column && !args.data.useFlag) {
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }
    },
    endCheckListGridHeaderCellInfo(args) {
      if (args.cell.column.field === "checkItem") {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
  },
};
</script>
