import { DatePicker, DateTimePicker } from "@syncfusion/ej2-calendars";
import moment from "moment";
import {
  DATE_FORMAT_YYYY_MM_DD,
  getCalendarInfoAndSetCalendarContentElementDayClassNew,
  getCurrentSelectedDateMomentInDateTimePicker,
  setIdInDatePickerSpan,
  setDateElementCssClassOnRenderCell,
} from "@/utils/date";

export default class GridDateTimeEdit {
  constructor(propertyName, format = "yyyy-MM-dd HH:mm:ss") {
    this.element = null;
    this.dateTimePicker = null;
    this.min = null;
    this.max = null;
    this.calendarListMap = new Map();

    return {
      create: () => {
        this.element = document.createElement("input");
        return this.element;
      },
      read: () => {
        return this.dateTimePicker.element.value;
      },
      destroy: () => {
        this.dateTimePicker.destroy();
      },
      write: (args) => {
        if (format === "yyyy-MM-dd") {
          this.dateTimePicker = new DatePicker({
            format: format,
            value: args.rowData[propertyName],
            floatLabelType: "Never",
            strictMode: true,
          });
        } else {
          this.dateTimePicker = new DateTimePicker({
            format: format,
            value: args.rowData[propertyName],
            floatLabelType: "Never",
            strictMode: true,
          });
        }

        // 휴장, 주말 표시를 위한 callback 추가
        if (this.dateTimePicker) {
          this.dateTimePicker.open = async () => {
            // map 초기화
            this.calendarListMap = new Map();

            // calendar data loading & calendar dom setting
            let openDatePickerDate;
            const value = this.dateTimePicker.value;
            if (value) {
              openDatePickerDate = moment(value);
            } else {
              openDatePickerDate = moment();
            }
            if (openDatePickerDate.isValid()) {
              await getCalendarInfoAndSetCalendarContentElementDayClassNew(
              this.dateTimePicker.contentElement,
              openDatePickerDate,
              this.calendarListMap
              );
            }
          };
          this.dateTimePicker.renderDayCell = (args) => {
            const date = moment(args.date).format(DATE_FORMAT_YYYY_MM_DD);
            const spanElement = args.element.firstElementChild;

            // 모든 날짜 span element에 오늘 날짜로 ID 설정
            setIdInDatePickerSpan(date, spanElement);

            // calendarListMap 에 데이터가 있으면 표시해주기
            setDateElementCssClassOnRenderCell(
            args.date,
            spanElement.parentElement,
            this.calendarListMap
            );
          };
          this.dateTimePicker.navigated = async (args) => {
            await getCalendarInfoAndSetCalendarContentElementDayClassNew(
            this.dateTimePicker.contentElement,
            moment(args.date),
            this.calendarListMap
            );
          };
        }

        // min/max 설정
        if (this.min) {
          this.dateTimePicker.min = this.min;
        }
        if (this.max) {
          this.dateTimePicker.max = this.max;
        }

        this.dateTimePicker.appendTo(this.element);

        this.dateTimePicker.element.addEventListener("keyup", async (e) => {
          const goToNextCursor = (pos) => {
            setTimeout(() => {
              e.target.setSelectionRange(pos, pos);
            }, 50);
          };
          const C_FORMAT = "yyyy-MM-DD HH:mm";
          const value = moment(new Date(this.dateTimePicker.value)).format(C_FORMAT);
          let cursorPos = Number(e.target.selectionStart);

          if (this.dateTimePicker.popupObj) {
            const focustDateMoment = getCurrentSelectedDateMomentInDateTimePicker(
            this.dateTimePicker
            );
            await getCalendarInfoAndSetCalendarContentElementDayClassNew(
            this.dateTimePicker.contentElement,
            focustDateMoment,
            this.calendarListMap
            );
          }
          if(e.target.selectionStart && e.key.length === 1){
            if ((/^\d+$/).test(e.key)) {
              const convertedDate = moment(
                  value.substring(0, e.target.selectionStart - 1) + e.key + value.substring(e.target.selectionStart, value.length), C_FORMAT
              );

              if(!convertedDate.isValid()){
                this.dateTimePicker.value = moment(value, C_FORMAT).toDate();
                goToNextCursor(--cursorPos);
                return;
              }
              this.dateTimePicker.value = convertedDate.toDate();

              (value.charAt(cursorPos) === " " || value.charAt(cursorPos) === ":" || value.charAt(cursorPos) === "-") ?
                  goToNextCursor(++cursorPos) :
                  goToNextCursor(cursorPos);
              return;
              //eslint-disable-next-line
            } else if(/^[a-zA-Z!@#$%^&*)\[\](+=._-]+$/.test(e.key)){
              this.dateTimePicker.value = moment(value, C_FORMAT).toDate();
              goToNextCursor(--cursorPos);
            }
          }
        });
      },
    };
  }
}
