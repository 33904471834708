<template>
  <div>
    <ejs-dialog
      ref="configTsConfCom2ByMemberDivPopup"
      :header="`환경설정-${popupTitle}-회원구분`"
      width="500"
      height="800"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="grid"
                        :dataSource="memberDivByIdx"
                        v-bind="gridOptions"
                        @queryCellInfo="onGridQueryCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {Edit, ForeignKey, Resize} from "@syncfusion/ej2-vue-grids";
import {
  getCommonCodeAttrb,
  putCommonCodeAttrb
} from "@/api/commonCode";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { orderBy as _orderBy } from "lodash";
import ErpButton from "@/components/button/ErpButton";
import {isInteger} from "@/utils/number";

export default {
  name: "ConfigTsConfCom2ByMemberDivPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  data() {
    return {
      memberDivByIdx: [],
      isSaved: false,
      confCom2MemberDiv: null,
      idx: 0,
    };
  },
  computed: {
    popupTitle() {
      return this.idx === 9
        ? "휴면 계정 사용"
        : this.idx === 10
        ? "락카존 선택 가능 여부"
        : "";
    },
    gridOptions() {
      return {
        provides: [
          Edit,
          Resize,
          ForeignKey,
        ],
        selectionSettings: {
          type: "Single",
          mode: "Row",
          enableToggle: false,
          persistSelection: false,
        },
        editSettings: {
          allowEditing: true,
          allowAdding: false,
          allowDeleting: false,
          mode: "Batch",
        },
        isInPopup: true,
        isNOColumnDisplay: false,
        columns: [
          {
            field: "comCode",
            headerText: "코드",
            type: "string",
            width: 90,
            allowEditing: false,
            isPrimaryKey: true,
          },
          {
            field: "comName",
            headerText: "코드명",
            type: "string",
            allowEditing: false,
            width: 90,
          },
          {
            field: "attrbByCheck",
            headerText: "선택",
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            width: 90,
            textAlign: "center",
          },
        ],
      };
    },
  },
  methods: {
    async showPopup(idx) {
      this.idx = idx;

      await this.fetch();
    },
    async fetch() {
      const {
        value: { comCodeAttrbList },
      } = await getCommonCodeAttrb("MEMBER_DIV", `COMMON_LD1`, this.idx);
      this.memberDivByIdx =
        _orderBy(
          comCodeAttrbList.map(item => ({
            ...item,
            attrbByCheck: item?.attrb === "Y",
          }))
          , ["sortNo"]
        )
      ;

      if (this.isSaved) {
        this.confCom2MemberDiv =
          _orderBy(
            comCodeAttrbList.filter(item => item.attrb === "Y").map(item => item.comName)
            , ["sortNo"]
          ).join(", ");
      }
    },
    onPopupClosed() {
      this.$emit("popupClosed", this.isSaved, this.confCom2MemberDiv);
    },
    onGridQueryCellInfo(args) {
      const {
        column: {
          field,
        },
        cell,
      } = args;

      if (field === "attrbByCheck") {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    async onSaveButtonClicked() {
      if (!isInteger(this.idx)) {
        this.errorToast("속성 IDX 값이 잘못되었습니다");
        return;
      }
      const {
        changedRecords,
      } = this.$refs.grid.getBatchChanges();

      if (!(changedRecords.length > 0)) {
        this.errorToast("수정된 데이터가 없습니다");
        return;
      }

      const saveData = {
        addedRecords: [],
        changedRecords: [],
        deletedRecords: [],
      };

      changedRecords.forEach(changedRecord => {
        changedRecord.attrb = changedRecord.attrbByCheck ? "Y" : "N";
        changedRecord.attrbName = changedRecord.attrbByCheck ? "Y" : "N";
        changedRecord.attrbDesc = "휴면 계정 사용";
        if (!changedRecord?.attrbId) {
          changedRecord.idx = this.idx;
          saveData.addedRecords.push(changedRecord);
        } else {
          saveData.changedRecords.push(changedRecord);
        }
      });

      this.memberDivByIdx
        .filter(item => !changedRecords.map(record => record.comCode).includes(item.comCode))
        .forEach(item => {
          if (!item?.attrbId) {
            item.attrb = "N";
            item.attrbName = "N";
            item.attrbDesc = this.title;
            item.idx = this.idx;
            saveData.addedRecords.push(item);
          }
        });

      await putCommonCodeAttrb(
        saveData.addedRecords,
        saveData.changedRecords,
        saveData.deletedRecords,
        "COMMON_LD1"
      );

      this.isSaved = true;

      this.infoToast(this.$t("main.popupMessage.saved"));

      this.fetch();
    },
    onCloseButtonClicked() {
      this.$refs.configTsConfCom2ByMemberDivPopup.hide();
    },
  },
};
</script>
