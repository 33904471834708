<template>
  <div>
    <ejs-dialog
      ref="createConfigCourseHoleInfoPopup"
      :header="`환경설정-코스정보`"
      width="1180"
      height="800"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onConfigCourseHoleInfoPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <div class="title">코스분류</div>
                    <ul class="content">
                      <li class="item">
                        <ejs-dropdownlist
                          ref="courseClassCodesDropDownList"
                          v-model="courseClass"
                          :dataSource="courseClassCodes"
                          :allowFiltering="false"
                          :fields="editparams.params.fields"
                          cssClass="lookup-condition-dropdown"
                        >
                        </ejs-dropdownlist>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      v-on:click.native="onConfigCourseHoleInfoGet"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-caption">
                    <div class="caption-navigation">
                      <ejs-tab
                          ref="tabByGrid"
                          :showCloseButton="false"
                          heightAdjustMode="Auto"
                          :selected="onTabSelected"
                          overflowMode="Popup"
                          :items="tabItems"
                      />
                    </div>
                  </div>
                  <div class="section-body">
                    <article class="body-article">
                      <!-- 아코디언 : accordion / 닫힘 : close -->
                      <section class="article-section section-0101">
                        <div class="section-body" style="border: none">
                          <div class="body-grid">
                            <ejs-grid-wrapper
                                ref="courseHoleGrid"
                                :allowResizing="true"
                                :dataSource="gridDataSource"
                                :isInPopup="true"
                                :aggregates="configCourseAggre"
                                :columns="configCourseColumn"
                                :provides="grid"
                                :editSettings="editSettings"
                                :cellSave="cellSave"
                                @queryCellInfo="courseHoleGridCustomiseCell"
                                @headerCellInfo="courseHoleGridHeaderCellInfo"
                            />
                          </div>
                          <div style="clear: both"></div>
                        </div>
                      </section>
                    </article>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  v-on:click.native="onConfigCourseHoleInfoSave"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onConfigCourseHoleInfoPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { getCourseHoleInfo, putCourseHoleInfo } from "@/api/systemConfig";
import {commonCodesGetCommonCode, commonCodesGetComName, commonCodesGetSortNo} from "@/utils/commonCodes";
import { Aggregate, Edit, ForeignKey } from "@syncfusion/ej2-vue-grids";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import { orderBy as _orderBy } from "lodash";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigCourseHoleInfoPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  created() {
    this.courseClassCodes.unshift({ comCode: "%", comName: "전체" });
  },
  computed: {
    tabItems() {
      return _orderBy(this.courseHoleList.map(item => ({
        ...item,
        courseCodeSortNo: commonCodesGetSortNo("COURSE_CODE", item.courseKindCode)
      })), ["courseCodeSortNo"]).map(data => {
        return {
          header: {
            text: commonCodesGetComName("COURSE_CODE", data.courseKindCode),
          },
          courseDiv: data.courseKindCode,
        };
      });
    },
    gridDataSource() {
      return this.courseHoleList.filter(item => item.courseKindCode === this.tabSelectCourseDiv)[0]?.courseList || [];
    },
  },
  mounted() {},
  data() {
    return {
      tabSelectIndex: 0,
      tabSelectCourseDiv: commonCodesGetCommonCode("COURSE_CODE", true)[0]?.comCode,
      courseClass: "%",
      greenCoercoinList: [
        { comCode: "상", comName: "상" },
        { comCode: "중", comName: "중" },
        { comCode: "하", comName: "하" },
      ],
      grid: [Edit, ForeignKey, Aggregate],
      configCourseAggre: [
        {
          columns: [
            {
              field: "courseCode",
              aggregationType: "TotalCaption",
              customAggregate: "소계",
            },
            {
              field: "par",
              stringFormat: "${value} PAR",
              aggregationType: "TotalSum",
            },
            {
              field: "hideHoleFlag",
              stringFormat: "${value} 개",
              aggregationType: "TotalSum",
            },
          ],
        },
      ],
      configCourseColumn: [
        {
          field: "_rid",
          allowEditing: false,
          visible: false,
        },
        {
          field: "courseHoleKey",
          allowEditing: false,
          isPrimaryKey: true,
          visible: false,
        },
        {
          field: "courseCode",
          headerText: "코스",
          allowEditing: false,
          width: "90",
          textAlign: "Left",
          groupCode: "COURSE_CODE",
          isCommonCodeField: true,
        },
        {
          field: "sortNo",
          headerText: "정렬",
          allowEditing: true,
          isNumericType: true,
          inputNumberProperty: { maxLength: 3 },
          type: "string",
          width: "90",
          textAlign: "Center",
        },
        {
          field: "holeDiv",
          headerText: "홀구분",
          allowEditing: false,
          width: "90",
          textAlign: "Left",
          editType: "dropdownedit",
          edit: this.editparams,
          groupCode: "HOLE_DIV_CODE",
          isCommonCodeField: true,
          valueAccessor: this.courseHoleValueAccessor,
        },
        {
          field: "par",
          headerText: "파",
          allowEditing: true,
          isNumericType: true,
          type: "string",
          inputNumberProperty: { maxLength: 3 },
          width: "90",
          textAlign: "Right",
        },
        {
          field: "dstnc",
          headerText: "거리",
          allowEditing: true,
          width: "90",
          isNumericType: true,
          type: "string",
          inputNumberProperty: { unit: "Yd" },
          textAlign: "Right",
        },
        {
          field: "greenCoercoin",
          headerText: "난이도",
          allowEditing: true,
          editType: "dropdownedit",
          edit: this.editparams,
          dataSource: this.greenCoercoinList,
          isCommonCodeField: true,
          width: "90",
          type: "string",
          textAlign: "Right",
        },
        {
          field: "handicap",
          headerText: "핸디캡",
          allowEditing: true,
          isNumericType: true,
          type: "string",
          inputNumberProperty: { maxLength: 3 },
          width: "90",
          textAlign: "Right",
        },
        {
          field: "hideHoleFlag",
          headerText: "숨김여부",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          width: "90",
          textAlign: "Center",
        },
        {
          field: "avgReqreTime",
          headerText: "평균소요시간(분)",
          allowEditing: true,
          isNumericType: true,
          inputNumberProperty: { maxLength: 3, unit: "분" },
          type: "string",
          width: "90",
          textAlign: "Right",
        },
      ],
      courseClassCodes: commonCodesGetCommonCode("COURSE_CLASS"),
      editparams: {
        params: {
          fields: { text: "comName", value: "comCode" },
          actionComplete: () => true,
        },
      },
      courseHoleList: [],
      editSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
        showConfirmDialog: false,
      },
    };
  },
  methods: {
    async onTabSelected(args) {
      if (this.tabSelectIndex !== args.selectedIndex) {
        const changedArr = this.$refs.courseHoleGrid.getBatchChanges().changedRecords;

        if (changedArr.flat().length > 0) {
          if (!(await this.confirm("수정사항이 있습니다. 탭을 이동하시겠습니까?"))) {
            this.$refs.tabByGrid.select(this.tabSelectIndex);
            return;
          }
        }
      }

      this.tabSelectIndex = args.selectedIndex;
      this.tabSelectCourseDiv = this.tabItems[args.selectedIndex].courseDiv;
    },
    courseHoleValueAccessor(field, data) {
      return data[field] + "홀";
    },
    async fetchCourseHoleList() {
      const {
        value: { courseInfo },
      } = await getCourseHoleInfo(
        this.courseClass === "%" ? null : this.courseClass
      );
      this.courseHoleList = courseInfo.map((element, index) => {
        return {
          ...element,
          _rid: index + 1,
          courseKindName: commonCodesGetComName("COURSE_CODE", element.courseKindCode),
        };
      });
    },
    async onShowConfigCourseHoleInfoPopup() {
      this.$refs.createConfigCourseHoleInfoPopup.show();
      await this.fetchCourseHoleList();
    },
    onConfigCourseHoleInfoPopupClosed() {
      this.$refs.createConfigCourseHoleInfoPopup.hide();
      this.$emit("popupClosed");
    },
    cellSave(e) {
      if (
        (isNaN(e.value) || e.value === null) &&
        (e.columnName.includes("handicap") ||
          e.columnName.includes("par") ||
          e.columnName.includes("avgReqreTime") ||
          e.columnName.includes("sortNo"))
      ) {
        e.value = 0;
      }
    },
    async onConfigCourseHoleInfoGet() {
      const changedArr = this.$refs.courseHoleGrid.getBatchChanges().changedRecords;

      if (changedArr.flat().length > 0) {
        if (!(await this.confirm("수정사항이 있습니다. 조회하시겠습니까?"))) {
          return;
        }
      }
      await this.fetchCourseHoleList();
    },
    async onConfigCourseHoleInfoSave() {
      if (this.$refs.courseHoleGrid.isEdit) {
        this.$refs.courseHoleGrid.saveCell();
      }

      const changedArr = this.$refs.courseHoleGrid.getBatchChanges().changedRecords.map((record) => {
        return {
          ...record,
          hideHoleFlag: record.hideHoleFlag || false,
        };
      });

      if (changedArr.flat().length < 1) {
        return;
      }
      await putCourseHoleInfo(changedArr.flat());
      this.infoToast(this.$t("main.popupMessage.saved"));
      await this.fetchCourseHoleList();
    },
    courseHoleGridCustomiseCell(args) {
      if (args.column.allowEditing === true) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    courseHoleGridHeaderCellInfo(args) {
      if (args.cell.column.field === "par") {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
  },
};
</script>
