<template>
  <div>
    <ejs-dialog
      ref="configAccountInfoPopup"
      :header="`환경설정-계좌 정보 설정`"
      width="880"
      height="600"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onConfigAccountInfoPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">계좌 정보 목록</div>
                    </div>
                    <div class="header-right">
                      <ul class="lookup-condition">
                        <li class="field">
                          <ul class="content">
                            <li class="item">
                              <ul class="check">
                                <li>
                                  <label>
                                    <input type="checkbox" v-model="useFlag" />
                                    <i></i>
                                    <div class="label">사용</div>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <div class="lookup-lookup">
                        <erp-button
                            button-div="GET"
                            :is-shortcut-button="true"
                            v-on:click.native="onConfigAccountInfoGet"
                        >
                          조회
                        </erp-button>
                      </div>
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="configAccountInfoPopup.shortcuts.addAccountInfo"
                              :shortcut="{key: 'F3'}"
                              v-on:click.native="onConfigAccountInfoAdd"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              @click.native="onConfigAccountInfoDelete"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="accountInfoGrid"
                        :allowResizing="true"
                        :dataSource="accountInfoList"
                        :isInPopup="true"
                        :columns="accountInfoColumn"
                        :provides="grid"
                        :editSettings="editSettings"
                        :selectionSettings="selectionOptions"
                        :cellSave="cellSave"
                        @queryCellInfo="accountInfoGridCustomiseCell"
                        @headerCellInfo="accountInfoGridHeaderCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onConfigAccountInfoSave"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onConfigAccountInfoPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Edit, ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { getAccountInfo, putAccountInfo } from "@/api/systemConfig";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import { maxBy as _maxBy } from "lodash";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigAccountInfoPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  async mounted() {
    await this.fetchAccountInfo();
  },
  data() {
    return {
      useFlag: true,
      accountUseDivs: commonCodesGetCommonCode("ACCOUNT_USE_DIV"),
      bankCodes: commonCodesGetCommonCode("BANK_CODE"),
      accountInfoList: [],
      grid: [Edit, ForeignKey, Resize],
      editparams: {
        params: {
          fields: { text: "comName", value: "comCode" },
          actionComplete: () => true,
        },
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Bottom",
      },
      selectionOptions: { type: "Multiple" },
    };
  },
  computed: {
    accountInfoColumn() {
      return [
        {
          field: "_rid",
          allowEditing: false,
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "accountUseDiv",
          headerText: "계좌사용구분",
          textAlign: "Left",
          width: 140,
          type: "string",
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "ACCOUNT_USE_DIV",
        },
        {
          field: "bankCode",
          headerText: "은행코드",
          textAlign: "Left",
          width: 140,
          type: "string",
          editType: "dropdownedit",
          isCommonCodeField: true,
          groupCode: "BANK_CODE",
        },
        {
          field: "acnutNo",
          headerText: "계좌번호",
          textAlign: "Left",
          width: 180,
          type: "string",
        },
        {
          field: "sortNo",
          headerText: "정렬",
          type: "number",
          format: "N",
          width: 70,
          textAlign: "center",
        },
        {
          field: "useFlag",
          headerText: "사용",
          type: "boolean",
          width: 70,
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "center",
        },
        {
          field: "remarks",
          headerText: "비고",
          textAlign: "Left",
          type: "string",
        },
        {
          field: "uniqueId",
          allowEditing: false,
          type: "string",
          visible: false,
        },
      ];
    },
  },
  methods: {
    async fetchAccountInfo() {
      const {
        value: { accountList },
      } = await getAccountInfo();
      let temp = accountList;
      if (this.useFlag) {
        temp = temp.filter((item) => item.useFlag === true);
      }
      this.accountInfoList = temp.map((element, index) => {
        return {
          ...element,
          _rid: index + 1,
        };
      });
      this.pureAccountInfoList = accountList;
    },
    onShowConfigAccountInfoPopup() {
      this.$refs.configAccountInfoPopup.show();
    },
    onConfigAccountInfoPopupClosed() {
      this.$emit("popupClosed");
      this.$refs.configAccountInfoPopup.hide();
    },
    async onConfigAccountInfoGet() {
      await this.fetchAccountInfo();
    },
    onConfigAccountInfoAdd() {
      let _rid = 0;
      let sortNo = 0;
      if (this.accountInfoList.length > 0) {
        const currentViewRecords = this.$refs.accountInfoGrid.getBatchCurrentViewRecords();
        _rid = _maxBy(currentViewRecords, "_rid")._rid;
        sortNo = _maxBy(currentViewRecords, "sortNo").sortNo + 1;
      } else {
        const { addedRecords } = this.$refs.accountInfoGrid.getBatchChanges();
        _rid = addedRecords.length;
      }

      const addRecordObject = {
        _rid: _rid + 1,
        accountUseDiv: this.accountUseDivs[0].comCode,
        bankCode: this.bankCodes[0].comCode,
        sortNo,
        useFlag: true,
      };
      this.$refs.accountInfoGrid.addRecord(addRecordObject);

      const currentviewRecords = this.$refs.accountInfoGrid.getBatchCurrentViewRecords();
      const idxToSelect = currentviewRecords.findIndex(
        (record) => record._rid === addRecordObject._rid
      );

      this.$refs.accountInfoGrid.editCell(idxToSelect, "accountUseDiv");
    },
    onConfigAccountInfoDelete() {
      const selectedRowIndexes =
        this.$refs.accountInfoGrid.getSelectedRowIndexes() - 1;

      this.$refs.accountInfoGrid.deleteRecord();
      this.$refs.accountInfoGrid.editCell(
        selectedRowIndexes >= 0 ? selectedRowIndexes : 0,
        "accountUseDiv"
      );
    },
    async onConfigAccountInfoSave() {
      if (!this.$refs.accountInfoGrid.validate()) {
        return;
      }

      const onlyAccountUseDivList = this.$refs.accountInfoGrid
        .getBatchCurrentViewRecords()
        .map((item) => item.accountUseDiv);

      let check = false;

      if (this.useFlag) {
        const hidedAccountUseDivList = this.pureAccountInfoList
          .filter((item) => item.useFlag === false)
          .map((item) => item.accountUseDiv);
        const checkList = [...onlyAccountUseDivList, ...hidedAccountUseDivList];
        check = checkList.some(
          (item) => checkList.indexOf(item) !== checkList.lastIndexOf(item)
        );
      } else {
        if (onlyAccountUseDivList) {
          check = onlyAccountUseDivList.some(
            (item) =>
              onlyAccountUseDivList.indexOf(item) !==
              onlyAccountUseDivList.lastIndexOf(item)
          );
        }
      }

      if (check) {
        return this.errorToast(
          "계좌사용구분에 " +
            this.$t("main.popupMessage.duplicatedRequiredData")
        );
      }

      const changedObj = this.$refs.accountInfoGrid.getBatchChanges();
      const tmpBody = {
        addedRecords: [],
        changedRecords: [],
        deletedRecords: [],
      };
      if (changedObj.addedRecords.length > 0) {
        tmpBody.addedRecords = changedObj.addedRecords;
      }
      if (changedObj.changedRecords.length > 0) {
        tmpBody.changedRecords = changedObj.changedRecords;
      }
      if (changedObj.deletedRecords.length > 0) {
        tmpBody.deletedRecords = changedObj.deletedRecords;
      }
      if (
        changedObj.changedRecords.length > 0 ||
        changedObj.addedRecords.length > 0 ||
        changedObj.deletedRecords.length > 0
      ) {
        await putAccountInfo(tmpBody);
        this.infoToast(this.$t("main.popupMessage.saved"));
        await this.fetchAccountInfo();
      }
    },
    accountInfoGridHeaderCellInfo(args) {
      if (
        args.cell.column.field === "accountUseDiv" ||
        args.cell.column.field === "bankCode"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    accountInfoGridCustomiseCell(args) {
      if (args.column.allowEditing === true) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (args.column && !args.data.useFlag) {
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }
    },
    cellSave(e) {
      if (
        (isNaN(e.value) || e.value === null) &&
        e.columnName.includes("sortNo")
      ) {
        e.value = 0;
      }
    },
  },
};
</script>
