<template>
  <div id="popupWrapper">
    <ejs-dialog
      ref="configMemberGradeSettingPopup"
      header="환경설정-회원등급제 설정"
      width="1500"
      heigt="800"
      allowDragging="true"
      showCloseIcon="true"
      isModal="false"
      @close="onConfigMemberGradeSettingPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-lookup">
              <div class="lookup-left">
                <ul class="lookup-condition">
                  <li class="field">
                    <ul class="content">
                      <li class="item">
                        <ul class="check">
                          <li>
                            <label>
                              <input
                                type="checkbox"
                                v-model="searchOptions.useFlag"
                              />
                              <i></i>
                              <div class="label">사용여부</div>
                            </label>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </li>
                </ul>
                <div class="lookup-lookup">
                  <erp-button
                      button-div="GET"
                      :is-shortcut-button="true"
                      @click.native="onSearchButtonClicked"
                  >
                    조회
                  </erp-button>
                </div>
              </div>
            </div>
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">회원등급 설정</div>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="memberGradeGrid"
                        height="250"
                        v-bind="memberGradeGridProps"
                        :isInPopup="true"
                        :validationModification="false"
                        @headerCellInfo="memberGradeGridHeaderCellInfo"
                        @queryCellInfo="memberGradeGridQueryCellInfo"
                      />
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">
                        내장횟수 설정 (1회 내장횟수별 점수)
                      </div>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="memberVisitGrid"
                        height="120"
                        v-bind="memberVisitGridProps"
                        :isInPopup="true"
                        :validationModification="false"
                        @headerCellInfo="memberVisitGridHeaderCellInfo"
                        @queryCellInfo="memberVisitGridQueryCellInfo"
                      />
                    </div>
                    <div>※ 정규홀(18홀, 18홀+) 내장객 횟수 집계</div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="closeConfigMemberGradeSettingPopup"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import routeViewMixin from "@/views/layout/mixin/routeViewMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import GolfErpAPI from "@/api/v2/GolfErpAPI";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import { Edit, ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import { numberWithCommas } from "@/utils/number";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigMemberGradeSettingPopup",
  mixins: [routeViewMixin, commonMixin, confirmDialogMixin],
  components: {
    EjsGridWrapper,
    ErpButton,
  },
  async mounted() {
    this.couponKinds = (await GolfErpAPI.fetchCouponUnitList())
      .filter((data) => data.couponKind === "M")
      .map((data) => ({
        ...data,
        couponKindId: data.kindId,
      }));

    this.couponKinds.push({
      couponKindId: 0,
      couponName: "",
    });
  },
  data() {
    return {
      searchOptions: {
        useFlag: false,
      },
      couponKinds: [
        {
          couponKindId: null,
          couponName: null,
        },
      ],
      memberGrades: [],
      memberVisits: [],
    };
  },
  computed: {
    memberVisitGridProps() {
      return {
        columns: [
          {
            field: "visitDiv",
            isPrimaryKey: true,
            headerText: "내장구분",
            width: 120,
            isCommonCodeField: true,
            groupCode: "VISIT_DIV",
            type: "string",
          },
          {
            field: "useFlag",
            headerText: "사용",
            width: 90,
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            textAlign: "Center",
          },
          {
            field: "applyCnt",
            headerText: "적용점수",
            textAlign: "right",
            width: 90,
            type: "string",
            isNumericType: true,
            inputNumberProperty: {
              allowDot: true,
              allowMinus: false,
              maxLength: 5,
            },
            valueAccessor: this.isZeroValueAccess,
          },
          {
            field: "remarks",
            headerText: "비고",
            textAlign: "left",
            type: "string",
            maxLength: 200,
          },
        ],
        dataSource: this.memberVisits,
        provides: [Edit, ForeignKey, Resize],
        selectionSettings: { type: "Single", enableToggle: false },
        validationRules: {
          applyCnt: {
            custom: {
              method: ({ applyCnt }) => {
                if (typeof applyCnt === "string") {
                  applyCnt = applyCnt.replace(/,/g, "");
                }

                return !(applyCnt < 0);
              },
              message: this.$t("main.validationMessage.minMessage", [
                "적용점수",
                "0",
              ]),
            },
          },
        },
      };
    },
    memberGradeGridProps() {
      return {
        columns: [
          {
            field: "memberGrade",
            isPrimaryKey: true,
            headerText: "변경 회원등급",
            width: 120,
            isCommonCodeField: true,
            groupCode: "MEMBER_GRADE",
            type: "string",
          },
          {
            field: "useFlag",
            headerText: "사용",
            width: 90,
            type: "boolean",
            editType: "booleanedit",
            displayAsCheckBox: true,
            textAlign: "Center",
          },
          {
            field: "memberGradeDown",
            headerText: "강등 회원등급",
            width: 120,
            editType: "dropdownedit",
            isCommonCodeField: true,
            groupCode: "MEMBER_GRADE",
            type: "string",
          },
          {
            field: "visitCntHeader",
            headerText: "적용점수",
            columns: [
              {
                field: "visitCntStart",
                headerText: "시작",
                textAlign: "right",
                width: 90,
                type: "string",
                isNumericType: true,
                inputNumberProperty: {
                  allowDot: true,
                  allowMinus: false,
                  maxLength: 5,
                },
                valueAccessor: this.isZeroValueAccess,
              },
              {
                field: "visitCntEnd",
                headerText: "끝",
                textAlign: "right",
                width: 90,
                type: "string",
                isNumericType: true,
                inputNumberProperty: {
                  allowDot: true,
                  allowMinus: false,
                  maxLength: 5,
                },
                valueAccessor: this.isZeroValueAccess,
              },
            ],
          },
          {
            headerText: "입장료",
            columns: [
              {
                field: "feeSalesStart",
                headerText: "시작",
                textAlign: "right",
                width: 90,
                type: "string",
                isNumericType: true,
                inputNumberProperty: {
                  maxLength: 10,
                },
                valueAccessor: this.isZeroValueAccess,
              },
              {
                field: "feeSalesEnd",
                headerText: "끝",
                textAlign: "right",
                width: 90,
                type: "string",
                isNumericType: true,
                inputNumberProperty: {
                  maxLength: 10,
                },
                valueAccessor: this.isZeroValueAccess,
              },
            ],
          },
          {
            headerText: "식음",
            columns: [
              {
                field: "foodSalesStart",
                headerText: "시작",
                textAlign: "right",
                width: 90,
                type: "string",
                isNumericType: true,
                inputNumberProperty: {
                  maxLength: 10,
                },
                valueAccessor: this.isZeroValueAccess,
              },
              {
                field: "foodSalesEnd",
                headerText: "끝",
                textAlign: "right",
                width: 90,
                type: "string",
                isNumericType: true,
                inputNumberProperty: {
                  maxLength: 10,
                },
                valueAccessor: this.isZeroValueAccess,
              },
            ],
          },
          {
            headerText: "프로샵",
            columns: [
              {
                field: "shopSalesStart",
                headerText: "시작",
                textAlign: "right",
                width: 90,
                type: "string",
                isNumericType: true,
                inputNumberProperty: {
                  maxLength: 10,
                },
                valueAccessor: this.isZeroValueAccess,
              },
              {
                field: "shopSalesEnd",
                headerText: "끝",
                textAlign: "right",
                width: 90,
                type: "string",
                isNumericType: true,
                inputNumberProperty: {
                  maxLength: 10,
                },
                valueAccessor: this.isZeroValueAccess,
              },
            ],
          },
          {
            field: "couponKindId",
            headerText: "쿠폰종류",
            textAlign: "left",
            width: 150,
            type: "string",
            editType: "dropdownedit",
            foreignKeyField: "couponKindId",
            foreignKeyValue: "couponName",
            dataSource: this.couponKinds,
          },
          {
            field: "remarks",
            headerText: "비고",
            textAlign: "left",
            type: "string",
            maxLength: 200,
          },
        ],
        dataSource: this.memberGrades,
        provides: [Edit, ForeignKey, Resize],
        selectionSettings: { type: "Single", enableToggle: false },
        validationRules: {
          memberGradeDown: {
            custom: {
              method: ({ useFlag, memberGradeDown }) => {
                if (useFlag) {
                  return !!memberGradeDown;
                }

                return true;
              },
              message: "main.validationMessage.requiredMessage",
            },
          },
          visitCntStart: {
            custom: {
              method: ({ visitCntStart }) => {
                if (typeof visitCntStart === "string") {
                  visitCntStart = visitCntStart.replace(/,/g, "");
                }

                return !(visitCntStart < 0);
              },
              message: this.$t("main.validationMessage.minMessage", [
                "적용점수 시작",
                "0",
              ]),
            },
          },
          visitCntEnd: {
            custom: {
              method: ({ visitCntEnd }) => {
                if (typeof visitCntEnd === "string") {
                  visitCntEnd = visitCntEnd.replace(/,/g, "");
                }

                return !(visitCntEnd < 0);
              },
              message: this.$t("main.validationMessage.minMessage", [
                "적용점수 끝",
                "0",
              ]),
            },
          },
        },
      };
    },
  },
  methods: {
    isZeroValueAccess(field, data) {
      let v = data[field];
      v = v ? (v === 0 ? "-" : numberWithCommas(v)) : "-";
      return v;
    },
    async showPopup() {
      await this.onSearchButtonClicked();
    },
    onConfigMemberGradeSettingPopupClosed() {
      this.$emit("popupClosed");
    },
    closeConfigMemberGradeSettingPopup() {
      this.$refs.configMemberGradeSettingPopup.hide();
    },
    async onSearchButtonClicked() {
      const data = await GolfErpAPI.fetchConfigMemberGrade();

      this.memberGrades = data.memberGrades.map((data) => ({
        ...data,
        useFlag:
          data.useFlag === null || data.useFlag === undefined
            ? false
            : data.useFlag,
        visitCntStart: data.visitCntStart ? data.visitCntStart : 0,
        visitCntEnd: data.visitCntEnd ? data.visitCntEnd : 0,
      }));

      this.memberVisits = data.memberVisits.map((data) => ({
        ...data,
        useFlag:
          data.useFlag === null || data.useFlag === undefined
            ? false
            : data.useFlag,
        applyCnt: data.applyCnt ? data.applyCnt : 0,
      }));

      if (this.searchOptions.useFlag) {
        this.memberGrades = this.memberGrades.filter((data) => data.useFlag);
        this.memberVisits = this.memberVisits.filter((data) => data.useFlag);
      }
    },
    async onSaveButtonClicked() {
      if (!this.$refs["memberGradeGrid"].validate()) {
        return;
      }

      if (!this.$refs["memberVisitGrid"].validate()) {
        return;
      }

      const {
        changedRecords: memberGrades,
      } = this.$refs.memberGradeGrid.getBatchChanges();
      const {
        changedRecords: memberVisits,
      } = this.$refs.memberVisitGrid.getBatchChanges();

      if (!(memberGrades.length > 0) && !(memberVisits.length > 0)) {
        this.errorToast(this.$t("main.validationMessage.noChanges"));
        return;
      }

      const memberGradesSaveData = memberGrades.map((data) => ({
        ...data,
        visitCntStart: data.visitCntStart
          ? typeof data.visitCntStart === "string"
            ? data.visitCntStart.replace(/,/g, "")
            : data.visitCntStart
          : 0,
        visitCntEnd: data.visitCntEnd
          ? typeof data.visitCntEnd === "string"
            ? data.visitCntEnd.replace(/,/g, "")
            : data.visitCntEnd
          : 0,
        feeSalesStart: data.feeSalesStart
          ? typeof data.feeSalesStart === "string"
            ? data.feeSalesStart.replace(/,/g, "")
            : data.feeSalesStart
          : 0,
        feeSalesEnd: data.feeSalesEnd
          ? typeof data.feeSalesEnd === "string"
            ? data.feeSalesEnd.replace(/,/g, "")
            : data.feeSalesEnd
          : 0,
        foodSalesStart: data.foodSalesStart
          ? typeof data.foodSalesStart === "string"
            ? data.foodSalesStart.replace(/,/g, "")
            : data.foodSalesStart
          : 0,
        foodSalesEnd: data.foodSalesEnd
          ? typeof data.foodSalesEnd === "string"
            ? data.foodSalesEnd.replace(/,/g, "")
            : data.foodSalesEnd
          : 0,
        shopSalesStart: data.shopSalesStart
          ? typeof data.shopSalesStart === "string"
            ? data.shopSalesStart.replace(/,/g, "")
            : data.shopSalesStart
          : 0,
        shopSalesEnd: data.shopSalesEnd
          ? typeof data.shopSalesEnd === "string"
            ? data.shopSalesEnd.replace(/,/g, "")
            : data.shopSalesEnd
          : 0,
        etcSalesStart: data.etcSalesStart
          ? typeof data.etcSalesStart === "string"
            ? data.etcSalesStart.replace(/,/g, "")
            : data.etcSalesStart
          : 0,
        etcSalesEnd: data.etcSalesEnd
          ? typeof data.etcSalesEnd === "string"
            ? data.etcSalesEnd.replace(/,/g, "")
            : data.etcSalesEnd
          : 0,
      }));

      const memberVisitsSaveData = memberVisits.map((data) => ({
        ...data,
        applyCnt: data.applyCnt
          ? typeof data.applyCnt === "string"
            ? data.applyCnt.replace(/,/g, "")
            : data.applyCnt
          : 0,
      }));

      const saveData = {
        memberGrades: memberGradesSaveData,
        memberVisits: memberVisitsSaveData,
      };

      await GolfErpAPI.putConfigMemberGrade(saveData);

      this.infoToast(this.$t("main.popupMessage.saved"));

      await this.onSearchButtonClicked();

      this.$EventBus.$emit("fetch");
    },
    memberGradeGridHeaderCellInfo(args) {
      const field = args.cell.column.field;

      if (field === "memberGradeDown" || field === "visitCntHeader") {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    memberVisitGridHeaderCellInfo(args) {
      const field = args.cell.column.field;

      if (field === "applyCnt") {
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    memberGradeGridQueryCellInfo(args) {
      const field = args.column.field;

      if (
        field === "useFlag" ||
        field === "memberGradeDown" ||
        field === "visitCntStart" ||
        field === "visitCntEnd" ||
        field === "feeSalesStart" ||
        field === "feeSalesEnd" ||
        field === "foodSalesStart" ||
        field === "foodSalesEnd" ||
        field === "shopSalesStart" ||
        field === "shopSalesEnd" ||
        field === "couponKindId" ||
        field === "remarks"
      ) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    memberVisitGridQueryCellInfo(args) {
      const field = args.column.field;

      if (field === "useFlag" || field === "applyCnt" || field === "remarks") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
  },
};
</script>
