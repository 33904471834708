<template>
  <div>
    <ejs-dialog
      ref="createConfigPenaltyPopup"
      :header="`환경설정-위약정보 설정`"
      width="1180"
      height="800"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onConfigPenaltyPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">위약정보 목록</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <div class="lookup-lookup">
                          <erp-button
                              button-div="GET"
                              :is-shortcut-button="true"
                              v-on:click.native="onConfigPenaltyGet"
                          >
                            조회
                          </erp-button>
                        </div>
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="configPenaltyPopup.shortcuts.addPenalty"
                              :shortcut="{key: 'F3'}"
                              v-on:click.native="onConfigPenaltyAdd"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              @click.native="onConfigPenaltyDelete"
                          >

                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="penaltyGrid"
                        :allowResizing="true"
                        :dataSource="penaltyList"
                        :isInPopup="true"
                        :columns="penaltyColumn"
                        :provides="grid"
                        :editSettings="editSettings"
                        :selectionSettings="selectionOptions"
                        @queryCellInfo="penaltyGridCustomiseCell"
                        @headerCellInfo="penaltyGridHeaderCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onConfigPenaltySave"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onConfigPenaltyPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodeGetComCodeDefaultValue } from "@/utils/commonCodes";
import { Edit, ForeignKey } from "@syncfusion/ej2-vue-grids";
import { getBlackListInfo, putBlackListInfo } from "@/api/systemConfig";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigPenaltyPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  async mounted() {
    await this.fetchPenaltyList();
  },
  data() {
    return {
      penaltyList: [],
      grid: [Edit, ForeignKey],
      penaltyColumn: [
        {
          field: "_rid",
          allowEditing: false,
          visible: false,
        },
        {
          field: "pkIdex",
          isPrimaryKey: "true",
          visible: false,
        },
        {
          field: "bsnCode",
          headerText: "영업구분",
          width: "90",
          textAlign: "Center",
          editType: "dropdownedit",
          edit: this.editparams,
          groupCode: "BSN_CODE",
          isCommonCodeField: true,
        },
        {
          field: "penltyCode",
          headerText: "위약코드",
          allowEditing: true,
          width: "90",
          textAlign: "Center",
          editType: "dropdownedit",
          edit: this.editparams1,
          groupCode: "PENLTY_CODE",
          isCommonCodeField: true,
        },
        {
          field: "penltyDiv",
          headerText: "위약구분",
          allowEditing: true,
          width: "90",
          textAlign: "Center",
          editType: "dropdownedit",
          edit: this.editparams1,
          groupCode: "PENLTY_DIV",
          isCommonCodeField: true,
        },
        {
          field: "occurOption",
          headerText: "발생옵션",
          allowEditing: true,
          width: "90",
          textAlign: "Center",
          editType: "dropdownedit",
          edit: this.editparams,
          groupCode: "OCCUR_OPTN",
          isCommonCodeField: true,
        },
        {
          field: "penltyLmttScope",
          headerText: "위약제한범위",
          allowEditing: true,
          width: "90",
          textAlign: "Center",
          editType: "dropdownedit",
          edit: this.editparams2,
          groupCode: "PENLTY_LMTT_SCOPE",
          isCommonCodeField: true,
        },
        {
          field: "mdcDayCnt",
          headerText: "제재일수",
          isNumericType: true,
          inputNumberProperty: { maxLength: 5 },
          type: "string",
          width: "90",
          textAlign: "Right",
        },
        {
          headerText: "마일리지",
          columns: [
            {
              field: "ddctMethod",
              headerText: "차감방법",
              allowEditing: true,
              width: "90",
              textAlign: "Center",
              editType: "dropdownedit",
              edit: this.editparams2,
              groupCode: "DDCT_METHOD",
              isCommonCodeField: true,
            },
            {
              field: "ddctScore",
              headerText: "차감점수",
              isNumericType: true,
              inputNumberProperty: {maxLength: 9},
              type: "string",
              width: "90",
              textAlign: "Right",
            },
          ]
        },
        {
          field: "penltyDem",
          headerText: "벌점",
          isNumericType: true,
          inputNumberProperty: { maxLength: 9 },
          type: "string",
          width: "90",
          textAlign: "Right",
        },
        {
          field: "penltyAmt",
          headerText: "위약금",
          isNumericType: true,
          inputNumberProperty: { maxLength: 13 },
          type: "string",
          width: "90",
          textAlign: "Right",
        },
        {
          field: "remarks",
          headerText: "비고",
          allowEditing: true,
          displayAsCheckBox: true,
          width: "90",
          textAlign: "Center",
          type: "string",
          maxLength: 400,
        },
      ],
      orginPenaltyList: [],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        newRowPosition: "Bottom",
        showConfirmDialog: false,
      },
      selectionOptions: { type: "Multiple" },
      editparams: {
        params: {
          fields: { text: "comName", value: "comCode" },
        },
      },
      editparams1: {
        params: {
          fields: { text: "comName", value: "comCode" },
        },
      },
      editparams2: {
        params: {
          fields: { text: "comName", value: "comCode" },
        },
      },
    };
  },
  methods: {
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    async fetchPenaltyList() {
      const {
        value: { penltyList },
      } = await getBlackListInfo();
      this.penaltyList = penltyList.map((element, index) => {
        return {
          ...element,
          _rid: index + 1,
        };
      });
      this.orginPenaltyList = JSON.parse(JSON.stringify(this.penaltyList));
    },
    onShowConfigPenaltyPopup() {
      this.$refs.createConfigPenaltyPopup.show();
    },
    onConfigPenaltyPopupClosed() {
      this.$emit("popupClosed");
      this.$refs.createConfigPenaltyPopup.hide();
    },
    async onConfigPenaltyGet() {
      await this.fetchPenaltyList();
    },
    onConfigPenaltyAdd() {
      this.$refs.penaltyGrid.addRecord({
        bsnCode: commonCodeGetComCodeDefaultValue("BSN_CODE"),
        occurOption: commonCodeGetComCodeDefaultValue("OCCUR_OPTN"),
        penltyLmttScope: commonCodeGetComCodeDefaultValue("PENLTY_LMTT_SCOPE"),
        mdcDayCnt: 0,
        penltyCode: commonCodeGetComCodeDefaultValue("PENLTY_CODE"),
      });
    },
    onConfigPenaltyDelete() {
      this.$refs.penaltyGrid.deleteRecord();
    },
    async onConfigPenaltySave() {
      const changedObj = this.$refs.penaltyGrid.getBatchChanges();
      const tmpBody = {
        addedRecords: [],
        changedRecords: [],
        deletedRecords: [],
      };
      if (changedObj.addedRecords.length > 0) {
        tmpBody.addedRecords = changedObj.addedRecords;
      }
      if (changedObj.changedRecords.length > 0) {
        tmpBody.changedRecords = changedObj.changedRecords.map((data) => {
          const originData = this.orginPenaltyList.find(
            (d) => d.pkIdex === data.pkIdex
          );
          data.pkBsnCode = originData.bsnCode;
          data.pkPenltyCode = originData.penltyCode;
          data.pkPenltyDiv = originData.penltyDiv;
          return data;
        });
      }
      if (changedObj.deletedRecords.length > 0) {
        tmpBody.deletedRecords = changedObj.deletedRecords;
      }
      if (
        changedObj.changedRecords.length > 0 ||
        changedObj.addedRecords.length > 0 ||
        changedObj.deletedRecords.length > 0
      ) {
        await putBlackListInfo(tmpBody);
        this.infoToast(this.$t("main.popupMessage.saved"));
        await this.fetchPenaltyList();
      }
    },
    penaltyGridCustomiseCell(args) {
      if (args.column.allowEditing === true) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    penaltyGridHeaderCellInfo(args) {
      if (
        args.cell.column.field === "bsnCode" ||
        args.cell.column.field === "occurOption" ||
        args.cell.column.field === "penltyLmttScope" ||
        args.cell.column.field === "mdcDayCnt" ||
        args.cell.column.field === "penltyCode"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
  },
};
</script>
