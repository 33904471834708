import request from "@/utils/request";

/**
 * @return Promise
 */
export function getCommonCodeMst(groupCode, groupName, schemaName, useFlag) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/com-code/mst",
    method: "get",
    params: {
      groupCode,
      groupName,
      schemaName,
      useFlag,
    },
  });
}

/**
 * @return Promise
 */
export function putCommonCodeMst(
  addedRecords,
  changedRecords,
  deletedRecords,
  deleteFlag,
  schemaName
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/com-code/mst",
    method: "put",
    data: {
      addedRecords,
      changedRecords,
      deletedRecords,
      deleteFlag,
      schemaName,
    },
  });
}

/**
 * @return Promise
 */
export function getCommonCodeDtl(groupCode, schemaName) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/com-code/dtl",
    method: "get",
    params: {
      groupCode,
      schemaName,
    },
  });
}

/**
 * @return Promise
 */
export function putCommonCodeDtl(
  addedRecords,
  changedRecords,
  deletedRecords,
  deleteFlag,
  schemaName
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/com-code/dtl",
    method: "put",
    data: {
      addedRecords,
      changedRecords,
      deletedRecords,
      deleteFlag,
      schemaName,
    },
  });
}

/**
 * @return Promise
 */
export function getCommonCodeAttrb(groupCode, schemaName, idx) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/com-code/attrb",
    method: "get",
    params: {
      groupCode,
      schemaName,
      idx,
    },
  });
}

/**
 * @return Promise
 */
export function putCommonCodeAttrb(
  addedRecords,
  changedRecords,
  deletedRecords,
  schemaName
) {
  return request({
    baseURL: process.env.VUE_APP_HOST_API_BASE_V1,
    url: "/code-management/com-code/attrb",
    method: "put",
    data: {
      addedRecords,
      changedRecords,
      deletedRecords,
      schemaName,
    },
  });
}
