<template>
  <div>
    <ejs-dialog
      ref="createConfigCardSupplierPopup"
      :header="`환경설정-카드사 매입처 설정`"
      width="1180"
      height="800"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onConfigCardSupplierPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">카드사 매입처 목록</div>
                    </div>
                    <div class="header-right">
                      <ul class="lookup-condition">
                        <li class="field">
                          <ul class="content">
                            <li class="item">
                              <ul class="check">
                                <li>
                                  <label>
                                    <input type="checkbox" v-model="useFlag" />
                                    <i></i>
                                    <div class="label">사용</div>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <div class="lookup-lookup">
                        <erp-button
                            button-div="GET"
                            :is-shortcut-button="true"
                            v-on:click.native="onConfigCardSupplierGet"
                        >
                          조회
                        </erp-button>
                      </div>
                      <ul class="header-button">
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="configCardSupplierPopup.shortcuts.addCardSupplier"
                              :shortcut="{key: 'F3'}"
                              v-on:click.native="onConfigCardSupplierAdd"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              @click.native="onConfigCardSupplierDelete"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="cardSupplierGrid"
                        :allowResizing="true"
                        :dataSource="cardSupplierList"
                        :isInPopup="true"
                        :validationRules="gridValidationRules"
                        :columns="cardSupplierColumn"
                        :provides="grid"
                        :editSettings="editSettings"
                        :selectionSettings="selectionOptions"
                        @cellSave="cellSave"
                        @queryCellInfo="cardSupplierGridCustomiseCell"
                        @headerCellInfo="cardSupplierGridHeaderCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onConfigCardSupplierSave"
              >
                저장
              </erp-button>

            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onConfigCardSupplierPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Edit, ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import { getCardCompanyInfo, putCardCompanyInfo } from "@/api/systemConfig";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import { maxBy as _maxBy } from "lodash";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigCardSupplierPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  async mounted() {
    await this.fetchCardSupplierInfo();
  },
  data() {
    return {
      useFlag: true,
      vanKindCodes: commonCodesGetCommonCode("VAN_KIND"),
      calMethodCodes: commonCodesGetCommonCode("VAT_CALC_METHOD"),
      cardSupplierList: [],
      grid: [Edit, ForeignKey, Resize],
      editparams: {
        params: {
          fields: { text: "comName", value: "comCode" },
          actionComplete: () => true,
        },
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Bottom",
      },
      selectionOptions: { type: "Multiple" },
    };
  },
  computed: {
    gridValidationRules() {
      const rule = {};
      commonCodesGetCommonCode("VAN_KIND").forEach((data) => {
        rule[data.comCode] = {
          maxLength: 50,
        };
      });
      return rule;
    },
    cardSupplierColumn() {
      const vanList = commonCodesGetCommonCode("VAN_KIND").map((data) => {
        return {
          field: data.comCode,
          headerText: data.comName,
          textAlign: "Center",
          type: "string",
          width: 70,
        };
      });
      return [
        {
          field: "_rid",
          allowEditing: false,
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "purcName",
          headerText: "매입처명",
          textAlign: "Left",
          width: 140,
          type: "string",
        },
        {
          field: "sortNo",
          headerText: "정렬",
          isNumericType: true,
          inputNumberProperty: { maxLength: 3 },
          type: "string",
          width: 70,
          textAlign: "Center",
        },
        ...vanList,
        {
          field: "freeRate",
          headerText: "수수료",
          isNumericType: true,
          inputNumberProperty: {
            allowDot: true,
            maxUnderDotLength: 3,
          },
          type: "string",
          width: 70,
          textAlign: "Right",
        },
        {
          field: "vatCalcMethod",
          headerText: "계산방식",
          textAlign: "Left",
          editType: "dropdownedit",
          edit: this.editparams,
          dataSource: this.calMethodCodes,
          isCommonCodeField: true,
          width: 100,
        },
        {
          field: "calcCphr",
          headerText: "계산자리수",
          isNumericType: true,
          inputNumberProperty: { maxLength: 3 },
          width: 70,
          textAlign: "Right",
        },
        {
          field: "useFlag",
          headerText: "사용",
          type: "boolean",
          width: 70,
          editType: "booleanedit",
          displayAsCheckBox: true,
          textAlign: "Center",
        },
      ];
    },
  },
  methods: {
    async fetchCardSupplierInfo() {
      const {
        value: { cardCompanyList },
      } = await getCardCompanyInfo(this.useFlag);
      this.cardSupplierList = cardCompanyList.map((element, index) => {
        this.vanKindCodes.forEach((van) => {
          if (!Object.prototype.hasOwnProperty.call(element, van.comCode)) {
            element[van.comCode] = null;
          }
        });
        return {
          ...element,
          _rid: index + 1,
        };
      });
    },
    onShowConfigCardSupplierPopup() {
      this.$refs.createConfigCardSupplierPopup.show();
    },
    onConfigCardSupplierPopupClosed() {
      this.$emit("popupClosed");
      this.$refs.createConfigCardSupplierPopup.hide();
    },
    async onConfigCardSupplierGet() {
      await this.fetchCardSupplierInfo();
    },
    onConfigCardSupplierAdd() {
      let _rid;
      if (this.cardSupplierList.length > 0) {
        const currentViewRecords = this.$refs.cardSupplierGrid.getBatchCurrentViewRecords();
        _rid = _maxBy(currentViewRecords, "_rid")._rid;
      } else {
        const { addedRecords } = this.$refs.cardSupplierGrid.getBatchChanges();
        _rid = addedRecords.length;
      }
      this.$refs.cardSupplierGrid.addRecord({
        purcName: null,
        vanMapCode: null,
        freeRate: 0,
        vatCalcMethod: this.calMethodCodes[0].comCode,
        calcCphr: 0,
        sortNo: 0,
        useFlag: false,
        _rid: _rid + 1,
      });
    },
    onConfigCardSupplierDelete() {
      this.$refs.cardSupplierGrid.deleteRecord();
    },
    async onConfigCardSupplierSave() {
      if (!this.$refs.cardSupplierGrid.validate()) {
        return;
      }
      const changedObj = this.$refs.cardSupplierGrid.getBatchChanges();
      const tmpBody = {
        addedRecords: [],
        changedRecords: [],
        deletedRecords: [],
      };
      if (changedObj.addedRecords.length > 0) {
        tmpBody.addedRecords = changedObj.addedRecords;
      }
      if (changedObj.changedRecords.length > 0) {
        tmpBody.changedRecords = changedObj.changedRecords;
      }
      if (changedObj.deletedRecords.length > 0) {
        tmpBody.deletedRecords = changedObj.deletedRecords;
      }
      if (
        changedObj.changedRecords.length > 0 ||
        changedObj.addedRecords.length > 0 ||
        changedObj.deletedRecords.length > 0
      ) {
        await putCardCompanyInfo(tmpBody);
        this.infoToast(this.$t("main.popupMessage.saved"));
        await this.fetchCardSupplierInfo();
      }
    },
    cardSupplierGridHeaderCellInfo(args) {
      if (
        args.cell.column.field === "purcName" ||
        args.cell.column.field === "vanMapCode"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    cardSupplierGridCustomiseCell(args) {
      if (args.column.allowEditing === true) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (args.column && !args.data.useFlag) {
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }
    },
    cellSave(e) {
      if (
        (isNaN(e.value) || e.value === null) &&
        (e.columnName.includes("sortNo") || e.columnName.includes("calcCphr"))
      ) {
        e.value = 0;
      }
    },
  },
};
</script>
