<template>
  <div>
    <ejs-dialog
      ref="roomCalenderConfPopup"
      :header="`환경설정-객실 캘린더 휴일 설정`"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onRoomCalenderConfPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body" style="border: none">
                    <div class="body-box">
                      요일 별 객실 주중/주말/성수기를 설정합니다.<br/>
                      설정된 값은 객실 캘린더를 만들 때 기준 값으로 사용됩니다.
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-body" style="border: none">
                    <div class="body-data header">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li
                              class="field"
                              style="width: calc((100% / 4) + 1px)"
                            >
                              <!-- 필수 : required -->
                              <div class="title">요일</div>
                            </li>
                            <li
                              class="field"
                              style="width: calc(((100% / 4) * 3) + 1px)"
                            >
                              <!-- 필수 : required -->
                              <div class="title required">구분</div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <div class="body-data body">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul
                            class="data-content"
                            v-for="item in roomCalenderConfs"
                            :key="item.IDX"
                          >
                            <li
                              class="field"
                              style="width: calc((100% / 4) + 1px)"
                            >
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group"
                                    style="text-align: center"
                                  >
                                    <span :style="{ color: commonCodesGetColorValue('DW_CODE', item.IDX.toString()) }">
                                      {{ commonCodesGetComName("DW_CODE", item.IDX.toString()) }}
                                    </span>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              class="field"
                              style="width: calc(((100% / 4) * 3) + 1px)"
                            >
                              <!-- 필수 : required -->
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="item.ATTRB"
                                            :value="'WEEKDAY'"
                                          />
                                          <i></i>
                                          <div class="label">주중</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="item.ATTRB"
                                            :value="'WEEKEND'"
                                          />
                                          <i></i>
                                          <div class="label">주말</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="item.ATTRB"
                                            :value="'SEASON'"
                                          />
                                          <i></i>
                                          <div class="label">성수기</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onSaveButtonClicked"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onCloseButtonClicked"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {
  commonCodesGetColorValue,
  commonCodesGetComName,
  commonCodesSetStandardInfos,
} from '@/utils/commonCodes';
import {
  getSystemConfigInfo,
  patchSystemConfigInfo
} from "@/api/systemConfig";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "RoomCalenderConfPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton,
  },
  mounted() {},
  data() {
    return {
      roomCalenderConfs: [],
    };
  },
  methods: {
    commonCodesGetColorValue,
    commonCodesGetComName,
    showPopup() {
      this.getSystemConfigInfo();
    },
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    async getSystemConfigInfo() {
      const {
        value: {
          confCom: {
            roomCalenderConf
          }
        },
      } = await getSystemConfigInfo();
      this.roomCalenderConfs = JSON.parse(roomCalenderConf);
    },
    onCloseButtonClicked() {
      this.$refs.roomCalenderConfPopup.hide();
    },
    onRoomCalenderConfPopupClosed() {
      this.$emit("popupClosed");
    },
    async onSaveButtonClicked() {
      const {
        data: {
          standardInfos
        }
      } = await patchSystemConfigInfo({
        roomCalenderConf: JSON.stringify(this.roomCalenderConfs)
      });

      this.infoToast(this.$t("main.popupMessage.saved"));

      // 시스템 환경설정 저장
      commonCodesSetStandardInfos(standardInfos);

      this.getSystemConfigInfo();
    },
  },
};
</script>
