<template>
  <div>
    <ejs-dialog
      allowDragging="true"
      header="SMS 문구 입력"
      ref="dialog"
      showCloseIcon="true"
      width="320"
      :position="{ x: 'center', y: 'center' }"
      :isModal="true"
      :visible="false"
      @close="onDialogClose"
      @overlayClick="onDialogOverlayClick"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body">
                    <input-textarea
                      ref="sendMessage"
                      v-model="data.value"
                      :rows="20"
                      maxlength="-1"
                    />
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="confirm keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-icon-custom="true"
                  @click.native="onSubmitClick"
              >
                확인
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<style scoped>
body .section-body > span {margin: 0;}
.e-input-group:not(.e-float-icon-left),
.e-input-group.e-success:not(.e-float-icon-left),
.e-input-group.e-warning:not(.e-float-icon-left),
.e-input-group.e-error:not(.e-float-icon-left),
.e-input-group.e-control-wrapper:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-success:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-warning:not(.e-float-icon-left),
.e-input-group.e-control-wrapper.e-error:not(.e-float-icon-left) {border-width: 0 !important;}
</style>

<script>
import InputTextarea from "@/components/common/text/InputTextarea";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigSmsTextInputPopup",
  components: {
    InputTextarea,
    ErpButton,
  },
  data() {
    return {
      data: {},
    };
  },
  methods: {
    show({ data }) {
      this.data = { ...data };
      this.$refs["dialog"].show();
    },
    hide() {
      this.$refs["dialog"].hide();
    },
    onDialogClose() {
      this.$emit("close");
    },
    onDialogOverlayClick() {
      this.hide();
    },
    onSubmitClick() {
      this.$emit("submit", {
        data: this.data,
      });
      this.hide();
    },
    onCloseClick() {
      this.hide();
    },
  },
};
</script>
