<template>
  <div>
    <ejs-dialog
      ref="createConfigSmsTextPopup"
      :header="`환경설정-SMS 문구 설정`"
      width="1400"
      height="800"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      @close="onPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">SMS 전송 목록</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <div class="lookup-lookup">
                          <erp-button
                              button-div="GET"
                              :is-shortcut-button="true"
                              :ignore="showConfigSmsTextInputPopup"
                              v-on:click.native="refreshSmsConfigRows"
                          >
                            조회
                          </erp-button>
                        </div>
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="configSmsTextPopup.shortcuts.addSmsText"
                              :shortcut="{key: 'F3'}"
                              :ignore="showConfigSmsTextInputPopup"
                              v-on:click.native="onAddClick"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              :ignore="showConfigSmsTextInputPopup"
                              @click.native="onDeleteClick"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="smsConfigGrid"
                        v-bind="smsConfigGridProps"
                        @headerCellInfo="onSmsConfigGridHeaderCellInfo"
                        @queryCellInfo="onSmsConfigGridQueryCellInfo"
                        @cellSave="onSmsConfigGridCellSave"
                        @recordClick="onSmsConfigGridRecordClick"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  :ignore="showConfigSmsTextInputPopup"
                  @click.native="onSaveClick"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  v-on:click.native="onCloseClick"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
    <config-sms-text-input-popup
      ref="config-sms-text-input-popup"
      @submit="onConfigSmsTextInputPopupSubmit"
      @close="onConfigSmsTextInputPopupClose"
    />
  </div>
</template>

<script>
import {
  sortBy as _sortBy,
  differenceBy as _differenceBy,
  maxBy as _maxBy,
} from "lodash";
import { Edit, ForeignKey, Resize } from "@syncfusion/ej2-vue-grids";
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  commonCodeGetComCodeDefaultValue,
  commonCodesGetCommonCode,
} from "@/utils/commonCodes";
import { getSmsWordInfo, putSmsWordInfo } from "@/api/systemConfig";
import { gridUtilGetTelColumnAccess } from "@/utils/gridUtil";
import EjsGridWrapper from "@/components/common/EjsGridWrapper";
import ConfigSmsTextInputPopup from "@/views/code-management/popup/ConfigSmsTextInputPopup";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigSmsTextPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ConfigSmsTextInputPopup,
    EjsGridWrapper,
    ErpButton,
  },
  async mounted() {
    await this.refreshSmsConfigRows();
  },
  data() {
    return {
      smsConfigRows: [],

      showConfigSmsTextInputPopup: false,
    };
  },
  computed: {
    smsSendTypes() {
      return commonCodesGetCommonCode("SMS_SEND_TYPE");
    },
    smsKinds() {
      return commonCodesGetCommonCode("SMS_KIND");
    },
    smsConfigGridProps() {
      return {
        allowFiltering: true,
        allowResizing: true,
        allowSelection: true,
        allowSorting: true,
        columns: [
          {
            allowEditing: true,
            dataSource: this.smsSendTypes,
            editType: "dropdownedit",
            field: "smsSendType",
            isCommonCodeField: true,
            headerText: "전송분류",
            minWidth: 16,
            textAlign: "Center",
            type: "string",
            width: 150,
          },
          {
            allowEditing: true,
            field: "order",
            headerText: "정렬",
            minWidth: 16,
            textAlign: "Center",
            type: "number",
            format: "N",
            width: 60,
          },
          {
            field: "orgSmsSendType",
            visible: false,
          },
          {
            allowEditing: false,
            field: "smsWords",
            headerText: "전송문구",
            minWidth: 16,
            textAlign: "Left",
            type: "string",
            width: 190,
          },
          {
            allowEditing: false,
            field: "aditWords",
            headerText: "추가문구",
            minWidth: 16,
            textAlign: "Left",
            type: "string",
            width: 190,
          },
          {
            allowEditing: true,
            displayAsCheckBox: true,
            editType: "booleanedit",
            field: "kakaoSendFlag",
            headerText: "카카오",
            minWidth: 16,
            textAlign: "Center",
            type: "boolean",
            width: 70,
          },
          {
            allowEditing: true,
            field: "kakaoTmplatCode",
            headerText: "카카오템플릿코드",
            minWidth: 16,
            textAlign: "Left",
            type: "string",
            width: 200,
          },
          {
            allowEditing: true,
            field: "attachedFile",
            headerText: "첨부파일",
            minWidth: 16,
            textAlign: "Left",
            type: "string",
            width: 100,
          },
          {
            allowEditing: true,
            dataSource: this.smsKinds,
            editType: "dropdownedit",
            field: "smsKind",
            isCommonCodeField: true,
            headerText: "전송방식",
            minWidth: 16,
            textAlign: "Center",
            type: "string",
            width: 100,
          },
          {
            allowEditing: true,
            editType: "telephoneedit",
            field: "dsptchNo",
            headerText: "연락처",
            minWidth: 16,
            textAlign: "Center",
            width: 100,
            type: "string",
            valueAccessor: (field, data) =>
              gridUtilGetTelColumnAccess(field, data),
          },
          {
            allowEditing: true,
            field: "updateTableName",
            headerText: "UPDATE_TABLE",
            minWidth: 16,
            textAlign: "Left",
            type: "string",
            width: 140,
          },
        ],
        dataSource: this.smsConfigRows,
        provides: [Edit, ForeignKey, Resize],
        noColumnWidth: 40,
        selectionSettings: { type: "Single", enableToggle: false },
        validationRules: {
          order: {
            required: true,
          },
          smsSendType: {
            required: true,
          },
          kakaoSendFlag: {
            required: true,
          },
          kakaoTmplatCode: {
            custom: {
              method: ({ kakaoSendFlag, kakaoTmplatCode }) => {
                if (kakaoSendFlag) {
                  return !!kakaoTmplatCode;
                }

                return true;
              },
              message: "main.validationMessage.requiredMessage",
            },
          },
          smsKind: {
            required: true,
          },
          dsptchNo: {
            required: true,
          },
        },
      };
    },
  },
  methods: {
    onShowConfigSmsTextPopup() {
      this.$refs.createConfigSmsTextPopup.show();
    },
    onPopupClosed() {
      this.$emit("popupClosed");
    },

    onSmsConfigGridHeaderCellInfo(args) {
      if (
        args.cell.column.field === "order" ||
        args.cell.column.field === "smsSendType" ||
        args.cell.column.field === "kakaoSendFlag" ||
        args.cell.column.field === "smsKind" ||
        args.cell.column.field === "dsptchNo"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
      if (
        args.cell.column.field === "smsWords" ||
        args.cell.column.field === "aditWords"
      ) {
        args.node.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onSmsConfigGridQueryCellInfo(args) {
      if (args.column.allowEditing === true) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (
        args.column.field === "smsWords" ||
        args.column.field === "aditWords"
      ) {
        args.cell.classList.add(this.$t("className.grid.clickArea"));
      }
    },
    onSmsConfigGridCellSave(args) {
      if (args.value === args.previousValue) {
        return;
      }

      if (args.columnName === "smsSendType") {
        const gridRef = this.$refs["smsConfigGrid"];
        const currentViewRecords = gridRef.getBatchCurrentViewRecords();

        if (
          currentViewRecords.reduce(
            (previousValue, { smsSendType }) =>
              previousValue || smsSendType === args.value,
            false
          )
        ) {
          args.value = args.previousValue;
          this.errorToast("이미 등록된 전송분류입니다");
        }
      }
    },
    onSmsConfigGridRecordClick(args) {
      if (
        args.column.field === "smsWords" ||
        args.column.field === "aditWords"
      ) {
        const gridRef = this.$refs["smsConfigGrid"];
        const currentViewRecords = gridRef.getBatchCurrentViewRecords();
        const currentRowIndex = currentViewRecords.findIndex(
          (currentViewRecord) => currentViewRecord._rid === args.rowData._rid
        );
        this.openConfigSmsTextInputPopup(
          currentRowIndex,
          args.column.field,
          currentViewRecords[currentRowIndex][args.column.field]
        );
      }
    },
    openConfigSmsTextInputPopup(rowIndex, field, value) {
      this.showConfigSmsTextInputPopup = true;
      this.$nextTick(() => {
        this.$refs["config-sms-text-input-popup"].show({
          data: { rowIndex, field, value },
        });
      });
    },
    onConfigSmsTextInputPopupSubmit({ data: { rowIndex, field, value } }) {
      this.$refs["smsConfigGrid"].updateCell(rowIndex, field, value);
    },
    onConfigSmsTextInputPopupClose() {
      this.showConfigSmsTextInputPopup = false;
    },

    async onSaveClick() {
      if (!this.$refs["smsConfigGrid"].validate()) {
        return;
      }

      await this.updateSmsConfigRows();
    },
    async onAddClick() {
      const gridRef = this.$refs["smsConfigGrid"];
      const currentViewRecords = gridRef.getBatchCurrentViewRecords();
      if (this.smsSendTypes.length === currentViewRecords.length) {
        this.errorToast("모든 분류가 입력된 상태입니다");
        return;
      }

      const defaultSmsSendType = commonCodeGetComCodeDefaultValue(
        "SMS_SEND_TYPE"
      );
      const defaultSmsKind = commonCodeGetComCodeDefaultValue("SMS_KIND");
      const order =
        (_maxBy(currentViewRecords, "order") || { order: 0 }).order + 1;
      const smsSendType = !currentViewRecords
        .map(({ smsSendType }) => smsSendType)
        .includes(defaultSmsSendType)
        ? defaultSmsSendType
        : _sortBy(
            _differenceBy(
              this.smsSendTypes,
              currentViewRecords.map((r) => ({
                ...r,
                comCode: r.smsSendType,
              })),
              "comCode"
            ),
            "sortNo"
          )[0].comCode;

      gridRef.addRecord({
        order,
        smsSendType,
        smsWords: "",
        aditWords: "",
        kakaoSendFlag: false,
        kakaoTmplatCode: "",
        smsKind: defaultSmsKind,
        dsptchNo: "",
        updateTableName: "",
      });
    },
    async onDeleteClick() {
      this.$refs["smsConfigGrid"].deleteRecord();
    },
    async onCloseClick() {
      this.$emit("popupClosed");
      this.$refs["createConfigSmsTextPopup"].hide();
    },

    async refreshSmsConfigRows() {
      this.smsConfigRows = await this.fetchSmsConfigRows();
      this.smsConfigRows = this.smsConfigRows.map((element, index) => {
        return {
          ...element,
          _rid: index + 1,
        };
      });
    },
    async updateSmsConfigRows() {
      const { addedRecords, changedRecords, deletedRecords } = this.$refs[
          "smsConfigGrid"
          ].getBatchChanges();

      if (
          !addedRecords?.length &&
          !changedRecords?.length &&
          !deletedRecords?.length
      ) {
        this.errorToast("수정사항이 없습니다");
        return;
      }

      await this.putSmsConfigRows(
          addedRecords,
          changedRecords,
          deletedRecords
      );

      this.infoToast(this.$t("main.popupMessage.saved"));

      await this.refreshSmsConfigRows();
    },

    async fetchSmsConfigRows() {
      const {
        value: { smsWordsList: smsConfigRows },
      } = await getSmsWordInfo();

      return _sortBy(smsConfigRows, ["order"]);
    },
    async putSmsConfigRows(
      addedSmsConfigRows,
      changedSmsConfigRows,
      deletedSmsConfigRows
    ) {
      await putSmsWordInfo({
        addedRecords: addedSmsConfigRows,
        changedRecords: changedSmsConfigRows,
        deletedRecords: deletedSmsConfigRows,
      });
    },
  },
};
</script>
