<template>
  <div>
    <ejs-dialog
      ref="createConfigSmsEndPersonPopup"
      :header="`환경설정-SMS 대상자`"
      width="1180"
      height="800"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onConfigSmsEndPersonPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">SMS 대상자 목록</div>
                    </div>
                    <div class="header-right">
                      <ul class="lookup-condition">
                        <li class="field">
                          <ul class="content">
                            <li class="item">
                              <ul class="check">
                                <li>
                                  <label>
                                    <input type="checkbox" v-model="useFlag" />
                                    <i></i>
                                    <div class="label">사용</div>
                                  </label>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </li>
                      </ul>
                      <ul class="header-button">
                        <div class="lookup-lookup">
                          <erp-button
                              button-div="GET"
                              :is-shortcut-button="true"
                              v-on:click.native="onConfigSmsEndPersonGet"
                          >
                            조회
                          </erp-button>
                        </div>
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="configSmsEndPersonPopup.shortcuts.addButton"
                              :shortcut="{key: 'F3'}"
                              v-on:click.native="onConfigSmsEndPersonAdd"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              @click.native="onConfigSmsEndPersonDelete"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="smsEndPersonGrid"
                        :allowResizing="true"
                        :dataSource="smsEndPersonList"
                        :isInPopup="true"
                        :validationRules="gridValidationRules"
                        :columns="smsEndPersonColumn"
                        :provides="grid"
                        :editSettings="editSettings"
                        :selectionSettings="selectionOptions"
                        :cellSave="cellSave"
                        @queryCellInfo="smsEndPersonGridCustomiseCell"
                        @headerCellInfo="smsEndPersonGridHeaderCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onConfigSmsEndPersonSave"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onConfigSmsEndPersonPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Edit, ForeignKey } from "@syncfusion/ej2-vue-grids";
import { gridUtilGetTelColumnAccess } from "@/utils/gridUtil";
import { getSmsLastInfo, putSmsLastInfo } from "@/api/systemConfig";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigSmsEndPersonPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  async mounted() {
    await this.fetchSmsEndPersonList();
  },
  data() {
    return {
      useFlag: true,
      grid: [Edit, ForeignKey],
      smsEndPersonColumn: [
        {
          field: "_rid",
          visible: false,
          isPrimaryKey: true,
        },
        {
          field: "sendTrget",
          headerText: "성명",
          allowEditing: true,
          width: "90",
          textAlign: "Center",
          type: "string",
        },
        {
          field: "hpNo",
          headerText: "핸드폰번호",
          allowEditing: true,
          width: "90",
          editType: "telephoneedit",
          textAlign: "Center",
          valueAccessor: (field, data) =>
            gridUtilGetTelColumnAccess(field, data),
          type: "string",
        },
        {
          field: "sortNo",
          headerText: "정렬",
          isNumericType: true,
          inputNumberProperty: { maxLength: 3 },
          type: "string",
          width: "35",
          textAlign: "Center",
        },
        {
          field: "useFlag",
          headerText: "사용",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          width: "90",
          textAlign: "Center",
        },
        {
          field: "closeSendFlag",
          headerText: "마감전송",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          width: "90",
          textAlign: "Center",
        },
        {
          field: "resveSendFlag",
          headerText: "예약전송",
          allowEditing: true,
          type: "boolean",
          editType: "booleanedit",
          displayAsCheckBox: true,
          width: "90",
          textAlign: "Center",
        },
        {
          field: "groupNo",
          headerText: "그룹번호",
          isNumericType: true,
          inputNumberProperty: { maxLength: 4 },
          type: "string",
          width: "90",
          textAlign: "Right",
        },
        {
          field: "groupName",
          headerText: "그룹명",
          type: "string",
          allowEditing: true,
          width: "90",
          textAlign: "Center",
        },
      ],
      smsEndPersonList: [],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        showConfirmDialog: false,
        newRowPosition: "Bottom",
      },
      selectionOptions: { type: "Multiple" },
      contactTelValueAccess: gridUtilGetTelColumnAccess,
      editparams: {
        params: {
          fields: { text: "comName", value: "comCode" },
          actionComplete: () => true,
        },
      },
      numericParams: {
        params: {
          validateDecimalOnType: true,
          decimals: 0,
          min: 0,
          format: "N",
        },
      },
      gridValidationRules: {
        sendTrget: {
          required: true,
          maxLength: 50,
        },
        hpNo: {
          maxLength: 50,
          required: true,
        },
        groupNo: {
          maxLength: 3,
          required: true,
        },
        groupName: {
          required: true,
          maxLength: 50,
        },
      },
    };
  },
  methods: {
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    async fetchSmsEndPersonList() {
      const {
        value: { tsConfSmsCloseList },
      } = await getSmsLastInfo(this.useFlag || null);
      this.smsEndPersonList = tsConfSmsCloseList.map((record, index) => {
        return {
          ...record,
          _rid: index + 1,
          pk: index + 10,
        };
      });
    },
    onShowConfigSmsEndPersonPopup() {
      this.$refs.createConfigSmsEndPersonPopup.show();
    },
    onConfigSmsEndPersonPopupClosed() {
      this.$emit("popupClosed");
      this.$refs.createConfigSmsEndPersonPopup.hide();
    },
    async onConfigSmsEndPersonGet() {
      if (this.$refs.smsEndPersonGrid.isGridModified()) {
        if (
          !(await this.confirm("변경된 데이터가 있습니다. 조회하시겠습니까?"))
        ) {
          return;
        }
      }
      await this.fetchSmsEndPersonList();
    },
    onConfigSmsEndPersonAdd() {
      this.$refs.smsEndPersonGrid.addRecord({
        sendTrget: null,
        hpNo: null,
        useFlag: true,
        closeSendFlag: false,
        resveSendFlag: false,
        groupNo: 0,
        groupName: null,
        sortNo: 0,
      });
    },
    onConfigSmsEndPersonDelete() {
      this.$refs.smsEndPersonGrid.deleteRecord();
    },
    async onConfigSmsEndPersonSave() {
      if (!this.$refs.smsEndPersonGrid.validate()) {
        return;
      }
      const changedObj = this.$refs.smsEndPersonGrid.getBatchChanges();
      const tmpBody = {
        addedRecords: [],
        changedRecords: [],
        deletedRecords: [],
      };
      if (changedObj.addedRecords.length > 0) {
        tmpBody.addedRecords = changedObj.addedRecords;
      }
      if (changedObj.changedRecords.length > 0) {
        tmpBody.changedRecords = changedObj.changedRecords;
      }
      if (changedObj.deletedRecords.length > 0) {
        tmpBody.deletedRecords = changedObj.deletedRecords;
      }
      if (
        changedObj.changedRecords.length > 0 ||
        changedObj.addedRecords.length > 0 ||
        changedObj.deletedRecords.length > 0
      ) {
        await putSmsLastInfo(tmpBody);
        this.infoToast(this.$t("main.popupMessage.saved"));
        await this.fetchSmsEndPersonList();
      }
    },
    smsEndPersonGridCustomiseCell(args) {
      if (args.column.allowEditing === true) {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
      }
      if (args.column && !args.data.useFlag) {
        args.cell.style.textDecoration = "line-through";
        args.cell.style.textDecorationColor = "red";
      }
    },
    smsEndPersonGridHeaderCellInfo(args) {
      if (
        args.cell.column.field === "sendTrget" ||
        args.cell.column.field === "groupNo" ||
        args.cell.column.field === "groupName" ||
        args.cell.column.field === "hpNo"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    cellSave(e) {
      if (e.columnName === "useFlag") {
        const idx = this.smsEndPersonList.findIndex(
          (x) => x.sendId === e.rowData.sendId
        );
        e.rowData.useFlag = e.value;
        this.$nextTick(() => {
          this.$refs.smsEndPersonGrid.updateRow(idx, e.rowData);
        });
      }
      if (
        (isNaN(e.value) || e.value === null) &&
        (e.columnName.includes("groupNo") || e.columnName.includes("sortNo"))
      ) {
        e.value = 0;
      }
    },
  },
};
</script>
