<template>
  <div>
    <ejs-dialog
      ref="createConfigWeekendDrwtPopup"
      :header="`환경설정-추첨 우선 순위`"
      width="500"
      height="800"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onConfigWeekendDrwtPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="weekendDrwtGrid"
                        :allowResizing="true"
                        :dataSource="weekendDrwtList"
                        :isInPopup="true"
                        @queryCellInfo="weekendDrwtGridCustomiseCell"
                        :isNOColumnDisplay="false"
                        @headerCellInfo="weekendDrwtGridHeaderCellInfo"
                        @onGridDialogDoubleClickedOrEnterKeyed="
                          onConfigWeekendDrwtSave
                        "
                        :columns="weekendDrwtColumn"
                        :provides="grid"
                        :editSettings="editSettings"
                        @cellSave="cellSave"
                      ></ejs-grid-wrapper>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onConfigWeekendDrwtSave"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onConfigWeekendDrwtPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { Edit, ForeignKey } from "@syncfusion/ej2-vue-grids";
import { getCommonCodeAttrb, putCommonCodeAttrb } from "@/api/commonCode";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigWeekendDrwtPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  async mounted() {
    await this.fetchWeekendDrwtList();
  },
  data() {
    return {
      weekendDrwtList: [{}],
      weekendDrwtColumn: [
        {
          field: "comCode",
          headerText: "코드",
          allowEditing: false,
          width: "90",
          isPrimaryKey: true,
          textAlign: "Center",
        },
        {
          field: "comName",
          headerText: "코드명",
          allowEditing: false,
          width: "90",
          textAlign: "Center",
        },
        {
          field: "attrb",
          headerText: "점수",
          isNumericType: true,
          inputNumberProperty: {
            maxLength: 3,
            allowDot: true,
            allowMinus: true,
            min: -1000,
            max: 1000,
          },
          type: "string",
          width: "90",
          textAlign: "Right",
        },
      ],
      grid: [Edit, ForeignKey],
      editSettings: {
        allowEditing: true,
        allowAdding: false,
        allowDeleting: false,
        mode: "Batch",
      },
      numericPointParams: {
        params: {
          validateDecimalOnType: true,
          decimals: 0,
          format: "N",
        },
      },
    };
  },
  methods: {
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    async fetchWeekendDrwtList() {
      const {
        value: { comCodeAttrbList },
      } = await getCommonCodeAttrb("MEMBER_DIV", `COMMON_LD1`, 6);
      this.weekendDrwtList = comCodeAttrbList;
    },
    onShowConfigWeekendDrwtPopup() {
      this.$refs.createConfigWeekendDrwtPopup.show();
    },
    onConfigWeekendDrwtPopupClosed() {
      this.$emit("popupClosed");
      this.$refs.createConfigWeekendDrwtPopup.hide();
    },
    weekendDrwtGridCustomiseCell(args) {
      const cellData = args.data;
      if (args.column.field === "attrb") {
        args.cell.classList.add(this.$t("className.grid.modifyArea"));
        if (Number.parseInt(cellData.attrb) < 0) {
          args.cell.style.color = "#FF0000";
        } else {
          args.cell.style.color = "#000000";
        }
      }
    },
    weekendDrwtGridHeaderCellInfo(args) {
      if (args.cell.column.field === "attrb") {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
    async onConfigWeekendDrwtSave() {
      const changedObj = this.$refs.weekendDrwtGrid.getBatchChanges();
      const tmpBody = {
        addedRecords: [],
        changedRecords: [],
        deletedRecords: [],
      };
      if (changedObj.addedRecords.length > 0) {
        tmpBody.addedRecords = changedObj.addedRecords;
      }
      if (changedObj.changedRecords.length > 0) {
        tmpBody.changedRecords = changedObj.changedRecords;
      }
      if (changedObj.deletedRecords.length > 0) {
        tmpBody.deletedRecords = changedObj.deletedRecords;
      }
      if (
        changedObj.changedRecords.length > 0 ||
        changedObj.addedRecords.length > 0 ||
        changedObj.deletedRecords.length > 0
      ) {
        await putCommonCodeAttrb(
          tmpBody.addedRecords,
          tmpBody.changedRecords,
          tmpBody.deletedRecords,
          `COMMON_LD1`
        );
        this.infoToast(this.$t("main.popupMessage.saved"));
        await this.fetchWeekendDrwtList();
      }
    },
    cellSave(e) {
      if (e.rowData && e.rowData.comCode === "NOT") {
        e.value = e.previousValue;
        this.errorToast("해당값은 변경할 수 없습니다");
      }
      const idx = this.weekendDrwtList.findIndex(
        (x) => x.comCode === e.rowData.comCode
      );
      this.$refs.weekendDrwtGrid.updateCell(idx, "attrb", e.value);
    },
  },
};
</script>
