<template>
  <div>
    <ejs-dialog
      ref="createConfigCalenderHolidayPopup"
      :header="`환경설정-캘린더`"
      :animationSettings="{ effect: 'None' }"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onConfigCalenderHolidayPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-body" style="border: none">
                    <div class="body-box">
                      "구분": 해당 요일을 영업일 / 휴일로 / 휴장 설정합니다.<br />
                      캘린더 설정을 종료한 후, 연도별 캘린더 관리에서 <br />
                      "구분" 에 의해 캘린더를 자동 생성 할 수 있습니다.
                    </div>
                  </div>
                </section>
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-02">
                  <div class="section-body" style="border: none">
                    <div class="body-data header">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul class="data-content">
                            <li
                              class="field"
                              style="width: calc((100% / 4) + 1px)"
                            >
                              <!-- 필수 : required -->
                              <div class="title">요일</div>
                            </li>
                            <li
                              class="field"
                              style="width: calc(((100% / 4) * 3) + 1px)"
                            >
                              <!-- 필수 : required -->
                              <div class="title required">구분</div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                    <div class="body-data body">
                      <div class="data-outer">
                        <div class="data-inner">
                          <ul
                            class="data-content"
                            v-for="item in holiDayData"
                            :key="item.IDX"
                          >
                            <li
                              class="field"
                              style="width: calc((100% / 4) + 1px)"
                            >
                              <div class="content">
                                <ul class="row">
                                  <li
                                    class="item form-group"
                                    style="text-align: center"
                                  >
                                    <span :style="{ color: commonCodesGetColorValue('DW_CODE', item.IDX.toString()) }">{{ dayArr[item.IDX - 1] }}</span>
                                  </li>
                                </ul>
                              </div>
                            </li>
                            <li
                              class="field"
                              style="width: calc(((100% / 4) * 3) + 1px)"
                            >
                              <!-- 필수 : required -->
                              <div class="content">
                                <ul class="row">
                                  <li class="item form-group">
                                    <!-- 포커스 : focus -->
                                    <ul class="check">
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="item.ATTRB"
                                            :value="'WEEKDAY'"
                                          />
                                          <i></i>
                                          <div class="label">주중</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="item.ATTRB"
                                            :value="'WEEKEND'"
                                          />
                                          <i></i>
                                          <div class="label">주말</div>
                                        </label>
                                      </li>
                                      <li>
                                        <label>
                                          <input
                                            type="radio"
                                            v-model="item.ATTRB"
                                            :value="'CLOSE'"
                                          />
                                          <i></i>
                                          <div class="label">휴장</div>
                                        </label>
                                      </li>
                                    </ul>
                                  </li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                          <div class="border-left"></div>
                          <div class="border-right"></div>
                          <div class="border-top"></div>
                          <div class="border-bottom"></div>
                        </div>
                      </div>
                      <div class="border-left"></div>
                      <div class="border-right"></div>
                      <div class="border-top"></div>
                      <div class="border-bottom"></div>
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onSaveCanlendrHoliday"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onConfigCalenderHolidayPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import {
  getCalenderHolidayInfo,
  putCalenderHolidayInfo,
} from "@/api/systemConfig";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import {commonCodesGetColorValue} from '@/utils/commonCodes';
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigCalenderHolidayPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ErpButton,
  },
  async mounted() {
    await this.fetchCalenderHolidayList();
  },
  data() {
    return {
      dayArr: ["일", "월", "화", "수", "목", "금", "토"],
      holiDayData: [],
    };
  },
  methods: {
    commonCodesGetColorValue,
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    async fetchCalenderHolidayList() {
      const {
        value: { calenderConf },
      } = await getCalenderHolidayInfo();
      this.holiDayData = JSON.parse(calenderConf);
    },
    onShowConfigCalenderHolidayPopup() {
      this.$refs.createConfigCalenderHolidayPopup.show();
    },
    onConfigCalenderHolidayPopupClosed() {
      this.$emit("popupClosed");
      this.$refs.createConfigCalenderHolidayPopup.hide();
    },
    async onSaveCanlendrHoliday() {
      await putCalenderHolidayInfo(JSON.stringify(this.holiDayData));
      this.infoToast(this.$t("main.popupMessage.saved"));
      await this.fetchCalenderHolidayList();
    },
  },
};
</script>
