<template>
  <div>
    <ejs-dialog
      ref="createConfigYearGrpReceptPopup"
      :header="`홈페이지 연단체 설정`"
      width="1180"
      height="800"
      :allowDragging="true"
      :showCloseIcon="true"
      :isModal="true"
      :close="onYearGrpConfigPopupClosed"
    >
      <div class="window">
        <div class="windowContent">
          <div class="content-wrapper">
            <div class="content-body">
              <article class="body-article">
                <!-- 아코디언 : accordion / 닫힘 : close -->
                <section class="article-section section-01">
                  <div class="section-header">
                    <div class="header-left">
                      <div class="header-title">설정 목록 {{`[${yearGrpOpenConfigDatasource.length}건]`}}</div>
                    </div>
                    <div class="header-right">
                      <ul class="header-button">
                        <div class="lookup-lookup">
                          <erp-button
                              button-div="GET"
                              :is-shortcut-button="true"
                              v-on:click.native="onYearGrpConfigGet"
                          >
                            조회
                          </erp-button>
                        </div>
                        <li class="add">
                          <erp-button
                              button-div="SAVE"
                              :is-icon-custom="true"
                              :is-custom-shortcut-button="true"
                              shortcut-key="configYearGrpPopup.shortcuts.addYearGrp"
                              :shortcut="{key: 'F3'}"
                              v-on:click.native="onYearGrpConfigButtonClicked"
                          >
                            추가
                          </erp-button>
                        </li>
                        <li class="delete">
                          <erp-button
                              button-div="DELETE"
                              :is-shortcut-button="true"
                              @click.native="onYearGrpConfigGridDelete"
                          >
                            삭제
                          </erp-button>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="section-body" style="border: none">
                    <div class="body-grid">
                      <ejs-grid-wrapper
                        ref="yearGrpConfigSettingGrid"
                        :allowResizing="true"
                        :dataSource="yearGrpOpenConfigDatasource"
                        :isInPopup="true"
                        :columns="yearGrpGridColumns"
                        :provides="grid"
                        :editSettings="editSettings"
                        :selectionSettings="selectionOptions"
                        :validation-rules="gridValidationRules"
                        @queryCellInfo="yearGrpGridCustomiseCell"
                        @headerCellInfo="yearGrpGridHeaderCellInfo"
                      />
                    </div>
                  </div>
                </section>
              </article>
            </div>
          </div>
        </div>
        <div class="windowFooter">
          <ul class="button">
            <li class="save keyColor">
              <erp-button
                  button-div="SAVE"
                  :is-key-color="true"
                  :is-shortcut-button="true"
                  @click.native="onYearGrpConfigGridSave"
              >
                저장
              </erp-button>
            </li>
            <li class="close">
              <erp-button
                  button-div="CLOSE"
                  @click.native="onYearGrpConfigPopupClosed"
              >
                닫기
              </erp-button>
            </li>
          </ul>
        </div>
      </div>
    </ejs-dialog>
  </div>
</template>

<script>
import commonMixin from "@/views/layout/mixin/commonMixin";
import { commonCodesGetCommonCode } from "@/utils/commonCodes";
import {Edit, ForeignKey, Resize, VirtualScroll} from "@syncfusion/ej2-vue-grids";
import {getGroupReceptConfiguration, putGroupReceptConfiguration} from "@/api/systemConfig";
import ejsGridWrapper from "@/components/common/EjsGridWrapper.vue";
import confirmDialogMixin from "@/views/layout/mixin/messagePopupDialogMixin";
import moment from "moment/moment";
import {DATE_FORMAT_YYYY_MM_DD} from "@/utils/date";
import GridDateTimeEdit from "@/views/layout/components/js/GridDateTimeEdit";
import ErpButton from "@/components/button/ErpButton";

export default {
  name: "ConfigPenaltyPopup",
  mixins: [commonMixin, confirmDialogMixin],
  components: {
    ejsGridWrapper,
    ErpButton,
  },
  async mounted() {
    await this.fetch();
  },
  data() {
    return {
      yearGrpOpenConfigDatasource: [],
      grid: [Edit, ForeignKey, Resize, VirtualScroll],
      yearGrpGridColumns: [
        {
          field: "grpKind",
          headerText: "단체종류",
          allowEditing: true,
          width: "80",
          textAlign: "Center",
          editType: "dropdownedit",
          edit: this.commonParams,
          groupCode: "GRP_KIND",
          isCommonCodeField: true,
        },
        {
          field: "requestYear",
          headerText: "신청년도",
          allowEditing: true,
          maxLength: 4,
          type: "string",
          width: "70",
          textAlign: "Center",
        },
        {
          field: "rceptStartDt",
          headerText: "접수시작일시",
          allowEditing: true,
          minWidth: 16,
          width: "160",
          textAlign: "Left",
          editType: "datepickeredit",
          edit: new GridDateTimeEdit('rceptStartDt', 'yyyy-MM-dd HH:mm'),
          isDatetimeType: true,
          valueAccessor: (field, data) => {
            return data.rceptStartDt && moment(data.rceptStartDt).format(`${DATE_FORMAT_YYYY_MM_DD} HH:mm`);
          },
        },
        {
          field: "rceptClosdeDt",
          headerText: "접수마감일시",
          allowEditing: true,
          minWidth: 16,
          width: "160",
          textAlign: "Left",
          editType: "datepickeredit",
          edit: new GridDateTimeEdit('rceptClosdeDt', 'yyyy-MM-dd HH:mm'),
          isDatetimeType: true,
          valueAccessor: (field, data) => {
            return data.rceptClosdeDt && moment(data.rceptClosdeDt).format(`${DATE_FORMAT_YYYY_MM_DD} HH:mm`);
          },
        },
        {
          field: "confirmDt",
          headerText: "확정일시",
          allowEditing: true,
          minWidth: 16,
          width: "160",
          textAlign: "Left",
          editType: "datepickeredit",
          edit: new GridDateTimeEdit('confirmDt', 'yyyy-MM-dd HH:mm'),
          isDatetimeType: true,
          valueAccessor: (field, data) => {
            return data.confirmDt && moment(data.confirmDt).format(`${DATE_FORMAT_YYYY_MM_DD} HH:mm`);
          },
        },
        {
          field: "requestLmttCnt",
          headerText: "신청제한",
          allowEditing: true,
          isNumericType: true,
          inputNumberProperty: {maxLength: 9},
          type: "string",
          width: "70",
          textAlign: "Center",
        },
        {
          field: "webOpenFlag",
          headerText: "웹오픈여부",
          editType: "booleanedit",
          width: "80",
          displayAsCheckBox: "true",
          type: "boolean",
          textAlign: "Center",
          allowEditing: true,
        },
        {
          field: "insertId",
          headerText: "등록자",
          type: "string",
          textAlign: "Center",
          allowEditing: false,
          width: "90",
        },
        {
          field: "insertDt",
          headerText: "등록일시",
          textAlign: "Left",
          type: "string",
          allowEditing: false,
          width: 130,
        },
        {
          field: "updateId",
          headerText: "수정자",
          textAlign: "Center",
          type: "string",
          allowEditing: false,
          width: 80,
        },
        {
          field: "updateDt",
          headerText: "수정일시",
          textAlign: "Center",
          type: "string",
          allowEditing: false,
          width: 130,
        },
      ],
      originDataGridSource: [],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Batch",
        newRowPosition: "Bottom",
        showConfirmDialog: false,
      },
      gridValidationRules: {
        grpKind: { required: true},
        requestYear: {
          required: true,
          maxLength: 4,
        },
        rceptStartDt: { required: true },
        rceptClosdeDt: { required: true },
        confirmDt: { required: true },
        requestLmttCnt: {
          required: true,
          min: 1,
          maxLength: 9,
        },
      },
      selectionOptions: { type: "Single" },
      commonParams: {
        params: {
          fields: { text: "comName", value: "comCode" },
        },
      },
    };
  },
  methods: {
    preventEnterEvent(event) {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    },
    async fetch() {
      const {value: {tsConfGrpRceptList}} = await getGroupReceptConfiguration();
      this.yearGrpOpenConfigDatasource = tsConfGrpRceptList;
      this.originDataGridSource = JSON.parse(JSON.stringify(this.yearGrpOpenConfigDatasource));
    },
    onYearGrpConfigPopupClosed() {
      this.$emit("popupClosed");
    },
    async onYearGrpConfigGet() {
      await this.fetch();
    },
    onYearGrpConfigButtonClicked() {
      this.$refs.yearGrpConfigSettingGrid.addRecord();
    },
    onYearGrpConfigGridDelete() {
      this.$refs.yearGrpConfigSettingGrid.deleteRecord();
    },
    async onYearGrpConfigGridSave() {
      if(!this.$refs.yearGrpConfigSettingGrid.validate()){
        return;
      }
      const validationResult = this.validateRecordRules();
      if(validationResult.length > 0) {
        if(validationResult.includes("SAME_DAY_RECORD")){
          this.errorToast("접수시작일시와 접수마감일시가 동일한 데이터가 있습니다. 확인 후 다시 저장하여 주십시오.");
          return;
        }
        if(validationResult.includes("BEFORE_DAY_RECORD")){
          this.errorToast("접수시작일시가 접수마감일시보다 미래 날짜입니다. 확인 후 다시 저장하여 주십시오.");
          return;
        }
        if (validationResult.includes("DUPLICATED_RECORD")) {
          this.errorToast("중복된 날짜의 신청정보가 존재합니다. 확인 후 다시 저장하여 주십시오.");
          return;
        }
      }
      const changes = this.$refs.yearGrpConfigSettingGrid.getBatchChanges();
      changes.addedRecords.map(record => {
        if (!(Object.keys(record).find(key => key === "webOpenFlag"))) {
          record["webOpenFlag"] = false;
        }
        return record;
      });
      this.convertedToFormalDate(changes);
      await putGroupReceptConfiguration(changes);
      this.infoToast(this.$t("main.popupMessage.saved"));

      await this.fetch();
    },
    convertedToFormalDate(recordChanges) {
      Object.values(recordChanges).map(recordCategory => {
        recordCategory.map(record => {
          Object.keys(record).map(key => {
            if (["confirmDt", "rceptStartDt", "rceptClosdeDt"].includes(key)) {
              record[key] = moment(record[key]).format("yyyy-MM-DD HH:mm:ss");
            }
          });
        });
      });
    },
    validateRecordRules() {
      const currentViewRecords = this.$refs.yearGrpConfigSettingGrid.getBatchCurrentViewRecords();
      const template = {};
      commonCodesGetCommonCode("GRP_KIND", true).map(({comCode}) => {
        template[comCode] = {};
      });
      currentViewRecords.map(({grpKind, requestYear}) => {
        template[grpKind][requestYear] = [];
      });
      currentViewRecords.map(({grpKind, requestYear, rceptStartDt, rceptClosdeDt}) => {
        template[grpKind][requestYear].push({rceptStartDt, rceptClosdeDt});
      });

      const validationResult = [];

      Object.keys(template).map(grpKey => {
        Object.keys(template[grpKey]).map(yearKey => {
          template[grpKey][yearKey].reduce((acc, currentDateValue) => {
            const currentDateStart = moment(currentDateValue.rceptStartDt);
            const currentDateEnd = moment(currentDateValue.rceptClosdeDt);

            if(currentDateStart.isSame(currentDateEnd)){
              validationResult.push("SAME_DAY_RECORD");
            }
            if(!currentDateStart.isSameOrBefore(currentDateEnd)){
              validationResult.push("BEFORE_DAY_RECORD");
            }

            if(acc.length > 0) {
              const {rceptStartDt, rceptClosdeDt} = acc.pop();
              const buffDateStart = moment(rceptStartDt);
              const buffDateEnd = moment(rceptClosdeDt);
              if(
                  !((currentDateEnd.isBefore(buffDateStart) && currentDateStart.isBefore(buffDateEnd)) ||
                  (currentDateEnd.isAfter(buffDateStart) && currentDateStart.isAfter(buffDateEnd)))
              ){
                validationResult.push("DUPLICATED_RECORD");
              }
            }
            acc.push(currentDateValue);
            return acc;
          }, []);
        });
      });
      return validationResult;
    },
    yearGrpGridCustomiseCell(args) {
      const {column, cell} = args;
      if (column.allowEditing) {
        cell.classList.add(this.$t("className.grid.modifyArea"));
      }
    },
    yearGrpGridHeaderCellInfo(args) {
      if (
        args.cell.column.field === "grpKind" ||
        args.cell.column.field === "requestYear" ||
        args.cell.column.field === "rceptStartDt" ||
        args.cell.column.field === "rceptClosdeDt" ||
        args.cell.column.field === "confirmDt" ||
        args.cell.column.field === "requestLmttCnt"
      ) {
        // 헤더에 필수영역 표시 클래스 추가
        args.node.classList.add(this.$t("className.grid.requiredInputHeader"));
      }
    },
  },
};
</script>
